import React from "react";
import {
  GET_RISK_PRESENCE_MAP_DATA_URL,
  GET_COMMUNITIES_URL,
  GET_COMMUNITY_PARISH_URL,
  GET_RISK_PRESENCE_DATE_URL,
} from "../apiEndPoints";

import mini_graphic_critical_infrastructure from "../imgs/mini_graphic_critical_infrastructure.png";
import mini_graphic_fishing from "../imgs/mini_graphic_fishing.png";
import mini_graphic_leisure from "../imgs/mini_graphic_leisure.png";
import mini_graphic_marine_navigation from "../imgs/mini_graphic_marine_navigation.png";
import mini_graphic_alternative_use_of_sargassum from "../imgs/mini_graphic_alternative_use_of_sargassum.png";
import mini_graphic_offshore_presence from "../imgs/mini_graphic_offshore_presence.png";
import mini_graphic_tourism from "../imgs/mini_graphic_tourism.png";
import mini_graphic_business_infrastructure from "../imgs/mini_graphic_business_infrastructure.png";
import beaching_risk_level_very_low from "../imgs/beaching_risk_level_very_low.png";
import beaching_risk_level_low from "../imgs/beaching_risk_level_low.png";
import beaching_risk_level_medium from "../imgs/beaching_risk_level_medium.png";
import beaching_risk_level_high from "../imgs/beaching_risk_level_high.png";
import beaching_risk_level_very_high from "../imgs/beaching_risk_level_very_high.png";
import beaching_risk_level_none from "../imgs/beaching_risk_level_none.png";

import risk_icon from "../imgs/high_risk_icon.png";
import risk_none_icon from "../imgs/risk_image_icon_none_grey.png";
import risk_minor_icon from "../imgs/risk_image_icon_orange_medium.png";
import risk_major_icon from "../imgs/risk_image_icon_red_high.png";

import zone_icon from "../imgs/zone_icon.png";
import { useState, useEffect } from "react";
import { Banner } from "./components/banner";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import offShoreIcon from "../imgs/assets/offshoreRiskIcon.png";
import specificCoastalActivitiesIcon from "../imgs/assets/specificCoastalActivitiesIcon.png";
import beachingRiskIcon from "../imgs/assets/beachingRiskIcon.png";
import nearshoreRiskIcon from "../imgs/assets/nearshoreRiskIcon.png";
import transformationalOpportunitiesIcon from "../imgs/assets/transformationalOpportunitiesIcon.png";
import { months } from "../constantData";

export var CommunityBulletin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [communityName, setCommunityName] = useState(
    "Select a community to begin."
  );
  const [parishName, setParishName] = useState(null);
  const [mapDate, setMapDate] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [posts, setPosts] = useState(null);
  var searchMonth = ""; // mapDate.split(" ")[0]
  var searchYear = ""; //mapDate.split(" ")[1]

  const [BusInfStatus, setBusInfStatus] = useState(null);
  const [CoaLeiStatus, setCoaLeiStatus] = useState(null);
  const [CritInfStatus, setCritInfStatus] = useState(null);
  const [FishingStatus, setFishingStatus] = useState(null);
  const [MarNavStatus, setMarNavStatus] = useState(null);
  const [TotSocialVStatus, setTotSocialVStatus] = useState(null);
  const [TourismStatus, setTourismStatus] = useState(null);
  const [OffshorePresence, setOffshorePresence] = useState(null);
  const [NearsID, setNearsID] = useState(null);
  const [OffsID, setOffsID] = useState(null);
  const [NearShoreRiskValue, setNearShoreRiskValue] = useState(null);
  const [OffShoreRiskValue, setOffShoreRiskValue] = useState(null);
  const [BeachingRiskValue, setBeachingRiskValue] = useState(null);

  const [showSection, setShowSection] = useState(false);
  console.log(showSection);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSection(true); // Set the state to trigger rerender
    }, 1000); // Set the desired time interval (in milliseconds)

    return () => clearTimeout(timeout); // Cleanup the timeout when component unmounts
  }, []);

  useEffect(() => {
    if (mapDate === null) {
      const fetchMapDate = async () => {
        try {
          const response = await fetch(GET_RISK_PRESENCE_DATE_URL);
          const jsonData = await response.json();
          setMapDate(
            months[parseInt(jsonData[0].map_month) - 1] +
              " " +
              jsonData[0].map_year
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchMapDate();
    }

    //get map data
    if (mapData === null) {
      const fetchMapData = async () => {
        try {
          const response = await fetch(GET_RISK_PRESENCE_MAP_DATA_URL);
          const jsonData = await response.json();
          setMapData(jsonData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchMapData();
    }

    //get community parish
    if (posts === null) {
      const fetchCommunitiesData = async () => {
        try {
          const response = await fetch(GET_COMMUNITIES_URL);
          const jsonData = await response.json();

          // Omit the first three items
          const postsWithoutFirstThree = jsonData.slice(3);

          setPosts(postsWithoutFirstThree);
          // console.log(postsWithoutFirstThree);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchCommunitiesData();
    }

    if (
      localStorage.getItem("communityName") === "none" ||
      localStorage.getItem("communityName") === "null"
    ) {
      setCommunityName("Select a community to begin.");
    } else {
      const fetchCommunityParish = async () => {
        try {
          const response = await fetch(
            GET_COMMUNITY_PARISH_URL(localStorage.getItem("communityName"))
          );
          const jsonData = await response.json();
          loadCommunityInfo(
            localStorage.getItem("communityName") +
              ", " +
              jsonData[0].parish_name
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchCommunityParish();
    }
  }, [mapDate, mapData, posts]); // Empty dependency list ensures the effect runs only once on initial render

  function loadCommunityInfo(communityInfo) {
    console.log("One");
  }
  function loadCommunityInfo2(communityInfo) {
    setMarNavStatus(null);
    setFishingStatus(null);
    setCoaLeiStatus(null);
    setCritInfStatus(null);
    setTourismStatus(null);
    setBusInfStatus(null);
    setTotSocialVStatus(0);
    setNearsID(null);
    setOffsID(null);
    setOffShoreRiskValue(null);
    setNearShoreRiskValue(null);
    setBeachingRiskValue(null);
    if (localStorage.getItem("mapDate") !== null) {
      searchMonth = localStorage.getItem("mapDate").split(" ")[0];
      searchYear = localStorage.getItem("mapDate").split(" ")[1];
    } else {
      searchMonth = mapDate.split(" ")[0];
      searchYear = mapDate.split(" ")[1];
    }

    // console.log("Two: ", communityInfo);
    var community_parish_SearchBy = communityInfo
      .split(",")
      .map((value) => value.trim());

    var searchby = "";
    //console.log(months.indexOf(searchMonth)+1)
    if (months.indexOf(searchMonth) + 1 < 10) {
      // eslint-disable-next-line no-useless-concat
      searchby = searchYear + "_" + "0" + (months.indexOf(searchMonth) + 1);
    } else {
      searchby = searchYear + "_" + (months.indexOf(searchMonth) + 1);
    }
    // var beaching_risk = "B" + searchby;
    // var nearshore_risk = "N" + searchby;
    // var offshore_risk = "O" + searchby;

    mapData.features.forEach((feature) => {
      if (
        community_parish_SearchBy[0] === feature.properties.NAME &&
        community_parish_SearchBy[1] === feature.properties.PARISH
      ) {
        // console.log("Community found");
        
        const {
          BusInf,
          CoaLei,
          CritInf,
          Fishing,
          MarNav,
          TotSocialV,
          Tourism,
        } = feature.properties;
        // console.log(Fishing);
        console.log(feature.properties);
        if (BusInf != null && BusInf > BusInfStatus) {
          setBusInfStatus(BusInf);
        } //
        if (CoaLei != null && CoaLei > CoaLeiStatus) {
          setCoaLeiStatus(CoaLei);
        } //
        if (CritInf != null && CritInf > CritInfStatus) {
          setCritInfStatus(CritInf);
        } //
        if (Fishing != null && Fishing > FishingStatus) {
          setFishingStatus(Fishing);
        } //
        if (MarNav != null && MarNav > MarNavStatus) {
          setMarNavStatus(MarNav);
        } //
        if (TotSocialV != null && TotSocialV > TotSocialVStatus) {
          setTotSocialVStatus(TotSocialV);
        }
        if (Tourism != null && Tourism > TourismStatus) {
          setTourismStatus(Tourism);
        } //

        if (
          feature.properties["O" + searchby] > 0 &&
          feature.properties["O" + searchby] > OffshorePresence
        ) {
          setOffshorePresence(feature.properties["O" + searchby]);
        }

        setCommunityName(feature.properties.NAME);
        setParishName(feature.properties.PARISH);
        setNearsID(feature.properties["Nears_ID"]);
        setOffsID(feature.properties["Offs_ID"]);
        // if (feature.properties["O" + searchby] > OffShoreRiskValue) {
          setOffShoreRiskValue(feature.properties["O" + searchby]);
        // }
        // if (feature.properties["N" + searchby] > NearShoreRiskValue) {
          setNearShoreRiskValue(feature.properties["N" + searchby]);
        // }
        // if (feature.properties["B" + searchby] > BeachingRiskValue) {
          setBeachingRiskValue(feature.properties["B" + searchby]);
        // }
        // console.log(feature.properties["B"+searchby])
        // console.log(searchby)
        // console.log("BEACHING: ", BeachingRiskValue)
      }
    });
  }

  function formatCommunityName(str) {
    if (str !== null) {
      let formattedName = str.replace(/\b\w+('|’)?\w*\b/g, function (match) {
        return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
      });

      formattedName = formattedName.replace(/\//g, "/").replace(/'/g, "'");

      return formattedName;
    }
  }

  // const boxStyle = {
  //   border: "1px solid #BFBFBF",
  //   borderRadius: 1,
  //   color: "#5B5B5B",
  //   p: 0.5,
  //   alignItems: "center",
  // };
  // const miniGraphic = {
  //   border: "1px solid #BFBFBF",
  //   borderRadius: 1,
  //   color: "#5B5B5B",
  //   px: 1,
  //   py: 0.5,
  //   width: "100px",
  // };

  return (
    <div className="sticky-top">
      <Banner title="Community Bulletin" backgroundColor="#28AA64" />

      <Box sx={{ padding: "52px 52px" }}>
        <div>
          <div className="pb-4">
            <p className="w-full font-bold text-center">
              National Sargassum Early Advisory System for Jamaica
            </p>
            <p className="text-[#3F76C9] font-bold text-center pb-4 w-full ">
              {mapDate}
            </p>
          </div>
          <div className="text-center flex flex-col align-center items-center justify-center mx-auto">
            {communityName === "Select a community to begin."
              ? formatCommunityName(communityName)
              : formatCommunityName(communityName) + ", " +
                formatCommunityName(parishName)}
            <div className="pt-4 pb-8">
              <TextField
                sx={{
                  width: "300px",
                }}
                value={
                  communityName && parishName
                    ? communityName + ", " + parishName
                    : "-Select a community to begin-"
                }
                placeholder="-Select a community to beging-"
                name="user-type"
                id="user-type"
                onChange={(e) => loadCommunityInfo2(e.target.value)}
                select
                label=""
                className=""
                InputLabelProps={{
                  shrink: true,
                  style: { color: "grey" },
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) =>
                    value === "" ? "-Select User Type-" : value,
                }}
              >
                {posts &&
                  posts.map((post) => {
                    return (
                      <MenuItem
                        value={`${post.community_name}, ${post.parish_name}`}
                      >
                        {/* {post.community_name + ", " + post.parish_name} */}
                        {formatCommunityName(post.community_name)},{" "}
                        {formatCommunityName(post.parish_name)}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>
          </div>
          <div className="w-[100%] overflow-x-auto">
            <Box className="w-[1200px] text-center justify-center items-center mx-auto">
              <Grid container spacing={3} columns={3}>
                <Grid sx={{}} item xs={1}>
                  <div className="">
                    <img className="" src={offShoreIcon} alt="" />
                    <div className="flex flex-col mt-[-130px]">
                      <p className="text-left text-[#E8BA58] font-bold">
                        Offshore Risk
                      </p>
                      <p className="max-w-[250px] md:max-w-[210px] text-left">
                        Floating mats of Sargassum expected offshore (30-75 km
                        from coastline)
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    {/* Offshore Risk Level */}
                    {OffShoreRiskValue != null ? (
                      <Box
                        className=""
                        sx={{
                          border: "1px solid #BFBFBF",
                          borderRadius: 1,
                          color: "#5B5B5B",
                          p: 0.5,
                          alignItems: "center",
                          textAlign: "center",
                          flexShrink: 0,
                          width: "min-content",
                        }}
                      >
                        <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                          {OffShoreRiskValue === 0 ? (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_none_icon}
                                alt=""
                              />
                              <p>NONE</p>
                            </div>
                          ) : OffShoreRiskValue === 1 ? (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_minor_icon}
                                alt=""
                              />
                              <p>MINOR</p>
                            </div>
                          ) : OffShoreRiskValue === 2 ? (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_major_icon}
                                alt=""
                              />
                              <p>MAJOR</p>
                            </div>
                          ) : (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_icon}
                                alt=""
                              />
                              <p>Risk Level</p>
                            </div>
                          )}
                        </div>
                      </Box>
                    ) : (
                      ""
                    )}

                    {/* Offshore Zone */}
                    {OffsID ? (
                      <div>
                        <div className="pt-2"></div>
                        <Box
                          className="flex"
                          sx={{
                            border: "1px solid #BFBFBF",
                            borderRadius: 1,
                            color: "#5B5B5B",
                            p: 0.5,
                            alignItems: "left",
                            textAlign: "left",
                            flexShrink: 0,
                            width: "min-content",
                          }}
                        >
                          <div className="flex gap-1">
                            <div className="offshore-risk-icons text-center">
                              <div className="bulletin-icon">
                                <div>
                                  <img src={zone_icon} alt=""></img>
                                  <p id="offshore-zone-text">{OffsID}</p>
                                </div>
                              </div>
                            </div>
                            {/* {offshoreRiskArray.length !== 0
                          ? offshoreRiskArray.map((area, index) => (
                              <Box sx={boxStyle} key={index}>
                                <p className="">{area}</p>
                              </Box>
                            ))
                          : null} */}
                          </div>
                        </Box>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <div className="flex flex-col text-center align-center items-center justify-center mx-auto ">
                    <img src={beachingRiskIcon} alt="" />
                    <p className="text-[#D86A9A] font-bold">
                      Beaching Risk for Communities
                    </p>
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <img
                    className=""
                    src={specificCoastalActivitiesIcon}
                    alt=""
                  />
                  <p className="mx-auto mr-[0px] mt-[-120px] text-right w-[200px] text-[#8538A4] font-bold">
                    Specific Coastal Activities Affected
                  </p>

                  <div className="text-right  mr-[0px] mx-auto w-[250px] flex flex-col gap-2">
                    <div className="flex justify-end mr-[0px]">
                      <div className="flex justify-end mr-[0px] overflow-x-auto flex-wrap gap-2">
                        {MarNavStatus > 0 ? (
                          <div
                            className="bulletin-icon"
                            id="marine-navigation-bulletin-icon"
                          >
                            <Box
                              className="flex h-full flex-col justify-center items-center mx-auto text-center align-center"
                              sx={{
                                border: "1px solid #BFBFBF",
                                borderRadius: 1,
                                color: "#5B5B5B",
                                p: 0.5,
                                flexShrink: 0,
                                width: "min-content",
                                textAlign: "center",
                              }}
                            >
                              <a href="/sectors-under-risk/#marine-navigation">
                                <div className="flex flex-col justify-center items-center mx-auto">
                                  <img
                                    className="w-[30px]"
                                    src={mini_graphic_marine_navigation}
                                    alt=""
                                  ></img>
                                  <p className="text-[14px]">
                                    MARINE<br></br>NAVIGATION
                                  </p>
                                </div>
                              </a>
                            </Box>
                          </div>
                        ) : (
                          ""
                        )}
                        {FishingStatus > 0 ? (
                          <div
                            className="bulletin-icon"
                            id="fishing-bulletin-icon"
                          >
                            <a href="/sectors-under-risk/#fishing-interests">
                              <Box
                                className="flex h-full  flex-col justify-center items-center mx-auto text-center align-center"
                                sx={{
                                  border: "1px solid #BFBFBF",
                                  borderRadius: 1,
                                  color: "#5B5B5B",
                                  p: 0.5,
                                  flexShrink: 0,
                                  width: "min-content",
                                  textAlign: "center",
                                }}
                              >
                                <div className="flex flex-col justify-center items-center mx-auto">
                                  <img
                                    className="w-[30px]"
                                    src={mini_graphic_fishing}
                                    alt=""
                                  ></img>
                                  <p className="text-[14px]">FISHING</p>
                                </div>
                              </Box>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {CoaLeiStatus > 0 ? (
                          <div
                            className="bulletin-icon"
                            id="leisure-bulletin-icon"
                          >
                            <a href="/sectors-under-risk/#leisure">
                              <Box
                                className="flex  h-full  flex-col justify-center items-center mx-auto text-center align-center"
                                sx={{
                                  border: "1px solid #BFBFBF",
                                  borderRadius: 1,
                                  color: "#5B5B5B",
                                  p: 0.5,
                                  flexShrink: 0,
                                  width: "min-content",
                                  textAlign: "center",
                                }}
                              >
                                <div className="flex flex-col justify-center items-center mx-auto">
                                  <img
                                    className="w-[30px]"
                                    src={mini_graphic_leisure}
                                    alt=""
                                  ></img>
                                  <p className="text-[14px]">LEISURE</p>
                                </div>{" "}
                              </Box>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {CritInfStatus > 0 ? (
                          <div
                            className="bulletin-icon "
                            id="critical-infrastructure-icon"
                          >
                            <a href="/sectors-under-risk/#critical-infrastructure">
                              <Box
                                className="flex flex-col justify-center items-center mx-auto text-center align-center"
                                sx={{
                                  border: "1px solid #BFBFBF",
                                  borderRadius: 1,
                                  color: "#5B5B5B",
                                  p: 0.5,
                                  flexShrink: 0,
                                  width: "min-content",
                                  textAlign: "center",
                                }}
                              >
                                <div className="flex flex-col justify-center items-center mx-auto">
                                  <img
                                    className="w-[30px]"
                                    src={mini_graphic_critical_infrastructure}
                                    alt=""
                                  ></img>
                                  <p className="text-[14px]">
                                    CRITICAL<br></br>INFRASTRUCTURE
                                  </p>
                                </div>{" "}
                              </Box>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {TourismStatus > 0 ? (
                          <div className="bulletin-icon" id="tourism-icon">
                            <a href="/sectors-under-risk/#tourism-interests">
                              <Box
                                className="flex h-full flex-col justify-center items-center mx-auto text-center align-center"
                                sx={{
                                  border: "1px solid #BFBFBF",
                                  borderRadius: 1,
                                  color: "#5B5B5B",
                                  p: 0.5,
                                  flexShrink: 0,
                                  width: "min-content",
                                  textAlign: "center",
                                }}
                              >
                                <div className="flex flex-col justify-center items-center mx-auto">
                                  <img
                                    className="w-[30px] mini-graphic-icon"
                                    src={mini_graphic_tourism}
                                    alt=""
                                  />
                                  <p className="text-[14px]">TOURISM</p>
                                </div>
                              </Box>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {BusInfStatus > 0 ? (
                          <div
                            className="bulletin-icon"
                            id="business-infrastructure-icon"
                          >
                            <Box
                              className="flex flex-col justify-center items-center mx-auto text-center align-center"
                              sx={{
                                border: "1px solid #BFBFBF",
                                borderRadius: 1,
                                color: "#5B5B5B",
                                p: 0.5,
                                flexShrink: 0,
                                width: "min-content",
                                textAlign: "center",
                              }}
                            >
                              <a href="/sectors-under-risk/#business-infrastructure">
                                <div className="flex flex-col justify-center items-center mx-auto">
                                  <img
                                    className="w-[30px] mini-graphic-icon"
                                    src={mini_graphic_business_infrastructure}
                                    alt=""
                                  />
                                  <p className="text-[14px]">
                                    BUSINESS<br></br>INFRASTRUCTURE
                                  </p>
                                </div>
                              </a>
                            </Box>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="eightpx-padding"></div>
                    <a href="/sectors-under-risk">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#9031AA",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        See Details
                      </Button>
                    </a>
                  </div>
                </Grid>

                <Grid sx={{}} item xs={1}>
                  <div className="">
                    <img
                      className="mt-[-70px]"
                      src={nearshoreRiskIcon}
                      alt=""
                    />
                    <div className="flex flex-col mt-[-20px]">
                      <p className="text-left text-[#DF9076] font-bold">
                        Nearshore Risk
                      </p>
                      <p
                        id="bulletin_section_nearshore_risk_paragraph"
                        className="max-w-[250px] text-left"
                      >
                        Floating mats of Sargassum expected nearshore (30 km
                        from coastline)
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    {NearShoreRiskValue != null ? (
                      <Box
                        className=""
                        sx={{
                          border: "1px solid #BFBFBF",
                          borderRadius: 1,
                          color: "#5B5B5B",
                          p: 0.5,
                          alignItems: "center",
                          textAlign: "center",
                          flexShrink: 0,
                          width: "min-content",
                        }}
                      >
                        <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                          {NearShoreRiskValue === 0 ? (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_none_icon}
                                alt=""
                              />
                              <p>NONE</p>
                            </div>
                          ) : NearShoreRiskValue === 1 ? (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_minor_icon}
                                alt=""
                              />
                              <p>MINOR</p>
                            </div>
                          ) : NearShoreRiskValue === 2 ? (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_major_icon}
                                alt=""
                              />
                              <p>MAJOR</p>
                            </div>
                          ) : (
                            <div className="flex flex-col text-center align-center justify-center mx-auto items-center">
                              <img
                                className="h-[30px] w-[30px]"
                                src={risk_icon}
                                alt=""
                              />
                              <p>Risk Level</p>
                            </div>
                          )}
                        </div>
                      </Box>
                    ) : (
                      ""
                    )}
                    {NearsID ? (
                      <div>
                        <div className="pt-2"></div>
                        <Box
                          className="flex"
                          sx={{
                            border: "1px solid #BFBFBF",
                            borderRadius: 1,
                            color: "#5B5B5B",
                            p: 0.5,
                            alignItems: "left",
                            textAlign: "left",
                            flexShrink: 0,
                            width: "min-content",
                          }}
                        >
                          <div className="bulletin-icon text-center">
                            {/* <a href="#"> */}
                            <div>
                              <img src={zone_icon} alt="" />
                              <p id="nearshore-zone-text">{NearsID}</p>
                            </div>
                            {/* </a> */}
                          </div>
                        </Box>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <p>
                    <span
                      className="text-[#D96F9C] font-bold"
                      id="impact_level_beaching_risk"
                    >
                      Impact level
                    </span>
                    <span className="bold">
                      {" "}
                      from Sargassum beaching due to:
                    </span>
                  </p>
                  <div>
                    <ul id="beaching-risk-list" className="text-align-left">
                      <li>
                        <span
                          id="beaching-risk-impact-level"
                          className="pink_bold_beaching_risk"
                        >
                        {console.log("HERE:",TotSocialVStatus)}
                          {TotSocialVStatus === 0 ? (
                            <span className="text-[#D96F9C] font-bold">No impact level</span>
                          ) : TotSocialVStatus === 1 ? (
                            <span className="text-[#D96F9C] font-bold">Very low impact level</span>
                          ) : TotSocialVStatus === 2 ? (
                            <span className="text-[#D96F9C] font-bold">Low impact level</span>
                          ) : TotSocialVStatus === 3 ? (
                            <span className="text-[#D96F9C] font-bold">Medium impact level</span>
                          ) : TotSocialVStatus === 4 ? (
                            <span className="text-[#D96F9C] font-bold">High impact level</span>
                          ) : TotSocialVStatus === 5 ? (
                            <span className="text-[#D96F9C] font-bold">Very high impact level</span>
                          ) : (
                            "Level Impact"
                          )}
                        </span>{" "}
                        of socio-economic exposure.
                      </li>
                      <li>
                        <span
                          id="beaching-risk-impact-level-of-vulnerability"
                          className="pink_bold_beaching_risk"
                        >
                          {BeachingRiskValue === 0 ? (
                            <span className="text-[#D96F9C] font-bold">No impact level</span>
                          ) : BeachingRiskValue === 1 ? (
                            <span className="text-[#D96F9C] font-bold">Very low impact level</span>
                          ) : BeachingRiskValue === 2 ? (
                            <span className="text-[#D96F9C] font-bold">Low impact level</span>
                          ) : BeachingRiskValue === 3 ? (
                            <span className="text-[#D96F9C] font-bold">Medium impact level</span>
                          ) : BeachingRiskValue === 4 ? (
                            <span className="text-[#D96F9C] font-bold">High impact level</span>
                          ) : BeachingRiskValue === 5 ? (
                            <span className="text-[#D96F9C] font-bold">Very high impact level</span>
                          ) : (
                            "Level Impact of vulnerability"
                          )}
                        </span>{" "}
                        to Sargassum beaching.
                      </li>
                    </ul>
                  </div>
                  <div className="flex pt-2 text-center align-center items-center mx-auto justify-center">
                    <Box
                      className=""
                      sx={{
                        border: "1px solid #BFBFBF",
                        borderRadius: 1,
                        color: "#5B5B5B",
                        p: 0.5,
                        alignItems: "center",
                        textAlign: "center",
                        flexShrink: 0,
                      }}
                    >
                      <div className="specific-coastal-activities-risk-icons">
                        <div className="bulletin-icon">
                          {/* <a href="#"> */}
                          <div className="flex flex-col text-center align-center items-center justify-center mx-auto">
                            <img
                              src={
                                BeachingRiskValue === 0
                                  ? beaching_risk_level_none
                                  : BeachingRiskValue === 1
                                  ? beaching_risk_level_very_low
                                  : BeachingRiskValue === 2
                                  ? beaching_risk_level_low
                                  : BeachingRiskValue === 3
                                  ? beaching_risk_level_medium
                                  : BeachingRiskValue === 4
                                  ? beaching_risk_level_high
                                  : BeachingRiskValue === 5
                                  ? beaching_risk_level_very_high
                                  : risk_icon
                              }
                              id="beach-risk-bulletin-icon"
                              alt=""
                            />
                            <p id="beach-risk-bulletin-text">
                              {BeachingRiskValue === 0 ? (
                                <div>
                                  Level 0<br></br>Very Low Impact
                                </div>
                              ) : BeachingRiskValue === 1 ? (
                                <div>
                                  Level 1<br></br>Very Low Impact
                                </div>
                              ) : BeachingRiskValue === 2 ? (
                                <div>
                                  Level 2<br></br>Low Impact
                                </div>
                              ) : BeachingRiskValue === 3 ? (
                                <div>
                                  Level 3<br></br>Medium Impact
                                </div>
                              ) : BeachingRiskValue === 4 ? (
                                <div>
                                  Level 4<br></br>High Impact
                                </div>
                              ) : BeachingRiskValue === 5 ? (
                                <div>
                                  Level 5<br></br>Very High Impact
                                </div>
                              ) : (
                                "Level Impact"
                              )}
                            </p>
                          </div>
                          {/* </a> */}
                        </div>
                      </div>
                      {/* <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                        <img src={risk_icon} alt="Risk Icon" />
                        <p>Alert Icon</p>
                        <p>Risk Level</p>
                      </div> */}
                    </Box>
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <img
                    className="mt-[-70px]"
                    src={transformationalOpportunitiesIcon}
                    alt=""
                  />
                  <p className="mx-auto mr-[0px] mt-[-10px] text-right w-[200px] text-[#C863D4] font-bold">
                    Transformational Opportunities
                  </p>
                  <div className="nearshore-risk-icons">
                    <div className="flex gap-2 justify-end mr-[0px]">
                      {OffShoreRiskValue === 1 || OffShoreRiskValue === 2 || NearShoreRiskValue === 1 || NearShoreRiskValue === 2  ? (
                        <div className="bulletin-icon">
                          <Box
                            className=""
                            sx={{
                              border: "1px solid #BFBFBF",
                              borderRadius: 1,
                              color: "#5B5B5B",
                              p: 0.5,
                              flexShrink: 0,
                              textAlign: "center",
                            }}
                          >
                            <div className="flex flex-col items-center ">
                              <img
                                src={mini_graphic_alternative_use_of_sargassum}
                                alt=""
                              />
                              <p id="alternative-use-bulletin-text">
                                Alternative Use<br></br>of Sargassum
                              </p>
                            </div>
                          </Box>
                        </div>
                      ) : (
                        ""
                      )}
                      {OffShoreRiskValue > 0 ? (
                        <div
                          className="bulletin-icon"
                          id="offshore-bulletin-icon"
                        >
                          <Box
                            className=""
                            sx={{
                              border: "1px solid #BFBFBF",
                              borderRadius: 1,
                              color: "#5B5B5B",
                              p: 0.5,
                              flexShrink: 0,
                              width: "min-content",
                              textAlign: "center",
                            }}
                          >
                            <div className="flex flex-col items-center">
                              <img
                                src={mini_graphic_offshore_presence}
                                alt=""
                              />
                              <p>
                                Offshore<br></br>Presence
                              </p>
                            </div>
                          </Box>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
};
