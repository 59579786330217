import React, { useRef } from "react";
// import emailjs from "@emailjs/browser";
import { useState, useEffect } from "react";
import { ADD_USER_TOKEN_URL, ADD_USER_URL, SEND_EMAIL_URL } from "../apiEndPoints";
import { loadPageUserType } from "../functions";
import { Banner } from "./components/banner";
import { Box } from "@mui/system";
import { MenuItem, TextField } from "@mui/material";
import { NotFoundPage } from "./404Page";
import { CustomButton } from "./components/buttons";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
 
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
 
export var AddUser = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const form = useRef();
  const user_type = document.querySelectorAll('input[name="user-type"]');
  const [showSection, setShowSection] = useState(false);
  const [userType, setUserType] = useState("");
  const [open, setOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSeverity, setNotificationSeverity] = useState("");

  const handleClick = (message, severity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setUserType(event.target.value);
  };

  useEffect(() => {
    setShowSection(loadPageUserType());
  }, []);

  for (const f of user_type) {
    if (f.checked) {
      console.log(f.value);
    }
  }
  async function addUserToPortal() {
    var first_name = await document.getElementById("first_name_input");
    var last_name = await document.getElementById("last_name_input");
    var email = await document.getElementById("email_input");
    var password_too = await document.getElementById("password_too");
    var password_token = await document.getElementById("password_token");
    var user_type_dropdown = await document.getElementById("user-type");
    // var user_type_value = "";

    console.log(first_name.value !== "");
    console.log(last_name.value !== "");
    console.log(email.value !== "");
    console.log(user_type.value !== "");

    if (
      first_name.value !== "" &&
      last_name.value !== "" &&
      email.value !== "" &&
      user_type.value !== ""
    ) {
      const alphaNum = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const len = alphaNum.length;
      let random_password = "";
      let random_token = "";

      for (let x = 0; x < 6; x++) {
        random_password += alphaNum[Math.floor(Math.random() * len)];
      }
      for (let x = 0; x < 6; x++) {
        random_token += alphaNum[Math.floor(Math.random() * len)];
      }

      password_too.value = random_password;
      password_token.value = random_token;

      // POST request using fetch()
      fetch(ADD_USER_URL, {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          first_name: first_name.value,
          last_name: last_name.value,
          email: email.value,
          password: random_password,
          user_type: userType, // user_type_value,
          status_type: "ACTIVE",
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        // Converting to JSON
        .then((response) => {
          response.json();

          if (response.ok === false) {
            alert("There was an error <error message>");
          } else {
            handleClick(
              "An email has been sent to the user with registration instructions.",
              "success"
            );

            fetch(SEND_EMAIL_URL, {
              method: "POST",
              body: JSON.stringify({
                type: "newSubscriber",
                email_to: email.value,
                name_to: first_name.value + " " + last_name.value,
                password_token: password_token,
                user_type: user_type,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then((response) => {
                // Add return statement here
                return response.json();
              })
              .then((data) => {
                // Handle the parsed JSON data here
                console.log(data);
  
                // Check for success or failure
                if (data && data.ok === false) {
                  console.log("There was an error: " + data.errorMessage);
                } else {
                  console.log("Successful");
                }
              })
              .catch((error) => {
                // Handle errors here
                console.error("Error:", error);
                console.log("There was an error: " + error.message);
              });
            // var serviceID = "service_dhtjcg1";
            // var templateID = "template_7l5pme5";
            // var publicKey = "o-N0qLZznsUbSnqIj";

            // emailjs
            //   .sendForm(serviceID, templateID, form.current, publicKey)
            //   .then(
            //     (result) => {
            //       console.log("USER HAS SUCESSFULLY BEEN ADDED.");
            //     },
            //     (error) => {
            //       console.log(error.text);
            //     }
            //   );

            for (const f of user_type) {
              if (f.checked) {
                f.value = "";
              }
            }
            var ele = document.getElementsByName("user-type");
            for (var i = 0; i < ele.length; i++) ele[i].checked = false;

            // POST request using fetch()
            fetch(ADD_USER_TOKEN_URL, {
              // Adding method type
              method: "POST",

              // Adding body or contents to send
              body: JSON.stringify({
                email: email.value,
                token: random_token,
              }),

              // Adding headers to the request
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              // Converting to JSON
              .then((response) => {
                response.json();

                if (response.ok === false) {
                  handleClick(
                    "Something went wrong, please sign out and log back in to try again.",
                    "warning"
                  );
                  // alert("There was an error <error message>");
                } else {
                  first_name.value = "";
                  last_name.value = "";
                  email.value = "";
                  password_too.value = "";
                  random_token = "";
                  setUserType("");

                  first_name.value = "";
                  last_name.value = "";
                  email.value = "";
                  password_too.value = "";
                  user_type_dropdown.value = "DEFAULT";

                  for (const f of user_type) {
                    if (f.checked) {
                      f.value = "";
                      // console.log(f.value)
                    }
                  }
                  var ele = document.getElementsByName("user-type");
                  for (var i = 0; i < ele.length; i++) ele[i].checked = false;
                }
              })
              // Displaying results to console
              .then((data) => {});
          }
        })
        .then((data) => {});
    } else {
      handleClick(
        "Please ensure all fields are filled out correctly.",
        "error"
      );
    }
  }

  return (
    <div>
      {showSection === "admin" ? (
        <div>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={notificationSeverity}
                sx={{
                  zIndex: 9999,
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {notificationMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Banner title="Add User" backgroundColor="#30AF6A" />
          <Box sx={{ padding: "32px 56px" }}>
            <p className="font-bold text-[24px]">Add User</p>
            <p className="text-[grey] pb-4 max-w-[700px]">
              Enter the details below necessary to create a new user. Once
              created, a default password will be sent to user after which they
              may log in an change.
            </p>
            <form className="flex flex-col gap-4 md:w-[450px]" ref={form}>
              <TextField
                required
                label="First Name"
                id="first_name_input"
                name="name_to"
                type="text"
                placeholder="e.g. John"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                required
                label="Last Name"
                id="last_name_input"
                name="name_to"
                type="text"
                placeholder="e.g. Doe"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                required
                label="Email"
                id="email_input"
                type="email"
                name="email_to"
                placeholder="e.g. johndoe@email.com"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                sx={{ display: "none" }}
                label="Password"
                id="password_too"
                type="hidden"
                name="password_too"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                sx={{ display: "none" }}
                label="Password 2"
                id="password_token"
                type="hidden"
                name="password_token"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                value={userType}
                placeholder="-Select User Type-"
                name="user-type"
                id="user-type"
                onChange={handleChange}
                select
                label="User Type"
                className="user-type-dropdown"
                InputLabelProps={{
                  shrink: true,
                  style: { color: "grey" },
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) =>
                    value === "" ? "-Select User Type-" : value,
                }}
              >
                <MenuItem key={1} value="DATA CONTRIBUTOR">
                  Data Contributor
                </MenuItem>
                <MenuItem key={2} value="ADMINISTRATOR">
                  Administrator
                </MenuItem>
              </TextField>
            </form>
            <div className="pt-4 flex">
              <div onClick={addUserToPortal}>
                <CustomButton label="Create User" style={{ type: "dark" }} />
              </div>
              <Link to="/view-users">
                <CustomButton label="Cancel" type="cancel" />
              </Link>
            </div>
          </Box>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};
