import LukeBuchanan from "./imgs/LukeBuchanan.png";
import JaduDash from "./imgs/JaduDash.png";
import SomnathParamanik from "./imgs/SomnathParamanik.png";
import KayviaHarriott from "./imgs/kayviaharriott_image.png";
import KevinJohnson from "./imgs/kevinjohnson_image.png";
import DouglasByfield from "./imgs/douglasbyfield_image.png";
import AvaMaxam from "./imgs/AvaMaxam.png";
import KaodiMcGaw from "./imgs/KaodiMcGaw.png";
import DarrenFletcher from "./imgs/DarrenFletcher.png";
import RomarioAnderson from "./imgs/RomarioAnderson.png";
import AjaniBissick from "./imgs/AjaniBissick.png";
import KishnaChambers from "./imgs/KishnaChambers.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
// import NoPictureTeamMember from "./imgs/UnknownTeamMember.png";

// Map Legend Data
export const RiskAndPresenceMapData = [
  {
    title: "Offshore & Nearshore Presence or Absence of Floating Sargassum",
    data: [
      { name: "absence", color: "#FFFFFF" },
      { name: "minor presence", color: "#E6C4C2" },
      { name: "major presence", color: "#D1A1F9" },
    ],
  },
  {
    title: "Shoreline Beaching Risk Level",
    data: [
      { name: "1 - very low", color: "rgba(204, 204, 204, 0.12)" },
      { name: "2 - low", color: "#9CCB48" },
      { name: "3 - medium", color: "#FFFF54" },
      { name: "4 - high", color: "#E78634" },
      { name: "5 - very high", color: "#E93323" },
    ],
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const project_members = [
  {
    name: "Luke Buchanan",
    title: "Acting Executive Director",
    photo: LukeBuchanan,
  },
  {
    name: "Romario Anderson",
    title: "Research Associate (Biogeographer)",
    photo: RomarioAnderson,
  },
  {
    name: "Ava Maxam",
    title: "WP2 Lead",
    photo: AvaMaxam,
  },

  {
    name: "Darren Fletcher",
    title: "Technical Projects Co-Ordinator",
    photo: DarrenFletcher,
  },
  {
    name: "Ajani Bissick",
    title: "GIS Officer",
    photo: AjaniBissick,
  },
  {
    name: "Kishna Chambers",
    title: "Project Co-Ordinator",
    photo: KishnaChambers,
  },
  {
    name: "Kaodi McGaw",
    title: "Project Manager",
    photo: KaodiMcGaw,
  },
  {
    name: "Kayvia Harriott",
    title: "Software Developer",
    photo: KayviaHarriott,
  },
  {
    name: "Kevin Johnson",
    title: "Software Developer",
    photo: KevinJohnson,
  },
  {
    name: "Douglas Byfield",
    title: "Software Developer",
    photo: DouglasByfield,
  },
  {
    name: "Jadu Dash",
    title: "WP2 Lead",
    photo: JaduDash,
  },
  {
    name: "Somnath Paramnik",
    title: "Research Support",
    photo: SomnathParamanik,
  },
];

export const mobileUserDropdown = [
  { name: "Add User", route: "/add-user", user: ["admin"] },
  { name: "Upload", route: "/upload", user: ["admin", "general"] },
  { name: "View Subscribers", route: "/view-subscribers", user: ["admin"] },
  { name: "View Users", route: "/view-users", user: ["admin", "general"] },

  {
    name: "Change Password",
    route: "/change-password",
    user: ["admin", "general"],
  },
  { name: "Sign Out", route: "/sucessfullogout", user: ["admin", "general"] },
  { name: "Subscribe to Our Newsletter", route: "/sign-up", user: ["all"] },
  { name: "Admin Log In", route: "/log-in", user: ["all"] },
];

export const pages = [
  { name: "Home", route: "/index" },
  { name: "National Bulletin", route: "/national-bulletin" },
  { name: "Community Bulletin", route: "/community-bulletin" },
  { name: "Risk and Presence Map", route: "/risk-presence-map" },
  { name: "Sectors Under Risk", route: "/sectors-under-risk" },
  { name: "About", route: "/about" },
];
export const adminDropDown = [
  { name: "Add User", route: "/add-user" },
  { name: "View Subscribers", route: "/view-subscribers" },
  { name: "View Users", route: "/view-users" },
  { name: "Change Password", route: "/change-password" },
];
export const quickLinks = [
  { name: "Home", route: "/index" },
  { name: "About", route: "/about" },
  { name: "Subscribe to Our Newsletter", route: "/sign-up" },
];

export const connectLinks = [
  {
    social: "Twitter",
    link: "https://twitter.com/mgimona?lang=en",
    icon: <TwitterIcon />,
  },
  {
    social: "FaceBook",
    link: "https://www.facebook.com/mgimona/",
    icon: <FacebookIcon />,
  },
  {
    social: "LinkedIn",
    link: "https://www.linkedin.com/company/mona-geoinformatics-institute-mgi/mycompany/",
    icon: <LinkedInIcon />,
  },
  {
    social: "Instagram",
    link: "https://www.instagram.com/mgimona/?hl=en",
    icon: <InstagramIcon />,
  },
];
