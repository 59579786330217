import React from "react";
import "../css/styles.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export let DefaultButton = ({ text, link, onClick }) => {
  const buttonStyle = {
    fontSize: "16px", // Change this to your desired font size
    fontWeight: "bold", // Change this to your desired font weight
    borderRadius: "5px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
  return (
    <div className="font-semibold text-18">
      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        style={buttonStyle}
        href={link}
      >
        {text}
      </Button>
    </div>
  );
};

/**Buttons */
export let ButtonRegularFillDiv = ({
  text,
  link,
  onClick,
  backgroundColor,
}) => {
  const buttonStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "5px",
    paddingTop: "8px",
    paddingBottom: "8px",
    width: "100%",
    textTransform: "capitalize",
    backgroundColor: backgroundColor,
  };
  return (
    <div className="font-semibold text-18">
      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        style={buttonStyle}
        href={link}
      >
        {text}
      </Button>
    </div>
  );
};

export let ButtonRegular = ({ text, link, onClick, backgroundColor }) => {
  const buttonStyle = {
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "2px",
    padding: "8px 24px",
    textTransform: "capitalize",
    backgroundColor: backgroundColor,
    color: "white", // Set a contrasting text color
    "&:hover": {
      backgroundColor: "#0D4F29", // Change this for hover effect
    },
  };

  return (
    <div className="font-semibold text-18">
      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        sx={buttonStyle}
        href={link}
      >
        {text}
      </Button>
    </div>
  );
};

/**Text Field */
export let TextFieldFillDiv = ({
  text,
  onChange,
  onKeyDown,
  placeholder,
  id,
  type,
  name,
}) => {
  const textFieldStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "5px",
    paddingTop: "8px",
    paddingBottom: "8px",
    width: "100%",
    textTransform: "capitalize",
    margin: "0px 0px",
  };
  return (
    <div className="font-semibold text-18">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id={id}
          label={text}
          name={name}
          type={type}
          onKeyDown={onKeyDown}
          onChange={onChange}
          placeholder={placeholder}
          style={textFieldStyle}
          variant="outlined"
        />
      </Box>
    </div>
  );
};

/*CheckBox*/

export let CheckBoxLabel = ({ label }) => {
  return <FormControlLabel control={<Checkbox />} label={label} />;
};
