import React from "react";
import { useState, useEffect } from "react";
import { GET_COMMUNITIES_URL } from "../apiEndPoints";

/**
 * The landing page component that displays the landing page for all users.
 */
export var CommunityListing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showPosts, setPosts] = useState([]);
  const communitiesByParish = {};

  useEffect(() => {
    fetch(GET_COMMUNITIES_URL, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);

        data.forEach((post) => {
          const parish = post.parish_name;

          if (!communitiesByParish[parish]) {
            communitiesByParish[parish] = [];
          }

          communitiesByParish[parish].push(post);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function formatCommunityName(str) {
    let formattedName = str.replace(/\b\w+('|’)?\w*\b/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
    });

    formattedName = formattedName.replace(/\//g, "/").replace(/'/g, "'");

    return formattedName;
  }

  return (
    <div>
      <div className="sub-header">
        <div className="sub-header-content sub-header-content-sectors-under-risk">
          <h1>Community Listing</h1>
        </div>
      </div>
      <div className="bodyC">
        <div className="sub-page-content">
          <div className="community-listing-page">
            <div className="text-align-center">
              <br />

              {showPosts === null ? (
                <p>"No communities found"</p>
              ) : (
                showPosts.map((post) => (
                  <div>
                    {formatCommunityName(post.community_name) +
                      ", " +
                      formatCommunityName(post.parish_name)}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
