import React from "react";
import { useState, useEffect } from "react";
import {
  GET_SUBSCRIBERS_URL,
  // SEND_EMAIL_URL,
  // UPDATE_COMMUNITY_BULLETIN_URL,
  // UPDATE_RISK_PRESENCE_DATE_URL,
  // UPDATE_RISK_PRESENCE_MAP_JSON_FILE_URL,
  // UPDATE_RISK_PRESENCE_MAP_OFFSHORE_NEARSHORE_FILE_URL,
  UPLOAD_COASTAL_FILE_DATA_URL,
} from "../apiEndPoints";
import { loadPageUserType } from "../functions";
import { GET_RISK_PRESENCE_DATE_URL } from "../apiEndPoints";
import { Banner } from "./components/banner";
import { Box, Button } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { NotFoundPage } from "./404Page";
import { CustomButton } from "./components/buttons";
import { Link } from "react-router-dom";
import { months } from "../constantData";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export var UploadRomario = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSeverity, setNotificationSeverity] = useState("");

  const handleClick = (message, severity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [showSection, setShowSection] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);
  const [posts, setPosts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs("2024-04-17"));
  const [mapMonth, setMapMonth] = useState("05");
  const [mapYear, setMapYear] = useState("2022");
  const [mapDay, setMapDay] = useState("05");

  const handleDateChange = (date) => {
    setSelectedDate(date);

    const year = date.format("YYYY").toString();
    const month = date.format("MM").toString();
    const day = date.format("DD").toString();
    setMapMonth(month);
    setMapDay(day);
    setMapYear(year);
  };

  useEffect(() => {
    setShowSection(loadPageUserType());

    fetch(GET_RISK_PRESENCE_DATE_URL)
      .then((response) => response.json())
      .then((json) => {
        setUpdateDate(
          months[parseInt(json[0].map_month) - 1] + " " + json[0].map_year
        );
      });

    fetch(GET_SUBSCRIBERS_URL, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  async function runFile() {

    const coastline_file_input = await document.getElementById(
      "coastline_file"
    );
    const offshore_nearshore_file_input = await document.getElementById(
      "offshore_nearshore_file"
    );
    // console.log(updateDate)
    // console.log(mapMonth+"_"+mapYear)
    if (
      // true
      coastline_file_input.files[0] != null &&
      offshore_nearshore_file_input.files[0] != null
    ) {
      handleClick("Map upload has begun, please do not leave the page as files are uploading.", "warning");

      console.log(coastline_file_input.files[0].text);
      console.log(offshore_nearshore_file_input.files[0].text);
      const formData = new FormData();

      formData.append("date", mapMonth + "_" + mapYear); //"02_2022");
      formData.append("coastline_file_data", coastline_file_input.files[0]);
      formData.append(
        "offshore_nearshore_file_data",
        offshore_nearshore_file_input.files[0]
      );
      for (const entry of formData.entries()) {
        console.log(entry);
      }

      const options = {
        method: "POST",
        headers: {
          // 'Content-Type': 'application/json',
        },
        body: formData,

        // JSON.stringify({
        //   date: "2024-03-08",
        //   coastline_data: coastline_file_input.files[0],
        //   offshore_nearshore_data: offshore_nearshore_file_input.files[0],
        // }),
      };

      fetch(UPLOAD_COASTAL_FILE_DATA_URL, options)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          handleClick("Map has successfully been uploaded.", "success");
        })
        .catch(error => {
          handleClick("Map has successfully been uploaded.", "success");
		console.error('Error:', error);
   
          // Log the JSON parsing error
          if (error instanceof SyntaxError) {
  handleClick("Map has successfully been uploaded.", "success");
		  console.error('JSON Parsing Error:', error.message);
              alert("File has been uploaded")
          }
       
          // handleClick("File not uploaded. Please check your internet connection and try again.", "error");

      });


      //          handleClick("Map has successfully been uploaded.", "success");

      // fetch(UPLOAD_COASTAL_FILE_DATA_URL, {
      //   // Adding method type
      //   method: "POST",
      //   body: {
      //     date: "03_2024", // Replace with your actual date value
      //     coastline_data: coastline_file_input.files[0],
      //     offshore_nearshore_data: offshore_nearshore_file_input.files[0],
      //   },

      //   // Adding headers to the request
      //   headers: {
      //     "Content-type": "application/json; charset=UTF-8",
      //   },
      // })
      //   // Converting to JSON
      //   .then((response) => {
      //     response.json();

      //     if (response.ok === false) {
      //       alert("There was an error <error message>");
      //     } else {
      //       console.log("Update made");
      //       // upload_type.value = "-Select an Upload Type-";
      //       // offshore_nearshore_file_input.files[0].value = "";
      //       // handleClick("Map has successfully been updated.", "success");
      //     }
      //   })
      //   // Displaying results to console
      //   .then((data) => {});
    }
  }

  // async function runFile2() {
  //   for (var i = 0; i < posts.length; i++) {
  //     console.log("Update date sent: " + updateDate);

  //     fetch(SEND_EMAIL_URL, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         type: "newUploadMap",
  //         email_to: posts[i].subscriber_email,
  //         name_to: posts[i].first_name + " " + posts[i].last_name,
  //         update_day: updateDate,
  //       }),
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       .then((response) => {
  //         // Add return statement here
  //         return response.json();
  //       })
  //       .then((data) => {
  //         // Handle the parsed JSON data here
  //         console.log(data);

  //         // Check for success or failure
  //         if (data && data.ok === false) {
  //           console.log("There was an error: " + data.errorMessage);
  //         } else {
  //           console.log("Successful");
  //         }
  //       })
  //       .catch((error) => {
  //         // Handle errors here
  //         console.error("Error:", error);
  //         console.log("There was an error: " + error.message);
  //       });
  //   }
  //   console.log("Email picture starting to capture");

  //   const coastline_file_input = await document.getElementById(
  //     "coastline_file"
  //   );
  //   const offshore_nearshore_file_input = await document.getElementById(
  //     "offshore_nearshore_file"
  //   );

  //   if (
  //     coastline_file_input.files[0] != null &&
  //     offshore_nearshore_file_input.files[0] != null
  //   ) {
  //     console.log("Coastline and offshore & nearshore files not empty.");

  //     fetch(UPDATE_RISK_PRESENCE_MAP_JSON_FILE_URL, {
  //       // Adding method type
  //       method: "PUT",
  //       body: coastline_file_input.files[0],

  //       // Adding headers to the request
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       // Converting to JSON
  //       .then((response) => {
  //         response.json();

  //         if (response.ok === false) {
  //           alert("There was an error <error message>");
  //         } else {
  //           console.log("Update made");
  //           // upload_type.value = "-Select an Upload Type-";
  //           coastline_file_input.files[0].value = "";
  //           handleClick("Map has successfully been uploaded.", "success");
  //         }
  //       })
  //       // Displaying results to console
  //       .then((data) => {});
  //     fetch(UPDATE_RISK_PRESENCE_DATE_URL, {
  //       // Adding method type
  //       method: "PUT",
  //       // Adding body or contents to send
  //       body: JSON.stringify({
  //         //2022-05-08
  //         map_year: mapYear,
  //         map_month: mapMonth,
  //         map_day: mapDay,
  //       }),
  //       // Adding headers to the request
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       // Converting to JSON
  //       .then((response) => {
  //         response.json();
  //         if (response.ok === false) {
  //           //alert("There was an error <error message>")
  //         } else {
  //           //alert("Subscriber has been added.")
  //           //subscriber_email.value = ""
  //         }
  //       })
  //       // Displaying results to console
  //       .then((data) => {
  //         handleClick("Map has successfully been uploaded.", "success");
  //       });

  //     fetch(UPDATE_RISK_PRESENCE_MAP_OFFSHORE_NEARSHORE_FILE_URL, {
  //       // Adding method type
  //       method: "PUT",
  //       body: offshore_nearshore_file_input.files[0],

  //       // Adding headers to the request
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       // Converting to JSON
  //       .then((response) => {
  //         response.json();

  //         if (response.ok === false) {
  //           alert("There was an error <error message>");
  //         } else {
  //           console.log("Update made");
  //           // upload_type.value = "-Select an Upload Type-";
  //           offshore_nearshore_file_input.files[0].value = "";
  //           handleClick("Map has successfully been updated.", "success");
  //         }
  //       })
  //       // Displaying results to console
  //       .then((data) => {});
  //   } else if (coastline_file_input.files[0] != null) {
  //     console.log("Coastline file not empty.");
  //     fetch(UPDATE_RISK_PRESENCE_MAP_JSON_FILE_URL, {
  //       // Adding method type
  //       method: "PUT",
  //       body: coastline_file_input.files[0],

  //       // Adding headers to the request
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       // Converting to JSON
  //       .then((response) => {
  //         response.json();

  //         if (response.ok === false) {
  //           alert("There was an error <error message>");
  //         } else {
  //           console.log("Update made");
  //           // upload_type.value = "-Select an Upload Type-";
  //           coastline_file_input.files[0].value = "";
  //           handleClick("Map has successfully been uploaded.", "success");
  //         }
  //       })
  //       // Displaying results to console
  //       .then((data) => {});

  //     fetch(UPDATE_RISK_PRESENCE_DATE_URL, {
  //       // Adding method type
  //       method: "PUT",
  //       // Adding body or contents to send
  //       body: JSON.stringify({
  //         //2022-05-08
  //         map_year: mapYear,
  //         map_month: mapMonth,
  //         map_day: mapDay,
  //       }),
  //       // Adding headers to the request
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       // Converting to JSON
  //       .then((response) => {
  //         response.json();
  //         if (response.ok === false) {
  //           //alert("There was an error <error message>")
  //         } else {
  //           //alert("Subscriber has been added.")
  //           //subscriber_email.value = ""
  //         }
  //       })
  //       // Displaying results to console
  //       .then((data) => {
  //         //displayAlertNotification("Map has successfully been uploaded.")
  //       });
  //   } else if (offshore_nearshore_file_input.files[0] != null) {
  //     console.log("Offshore & Nearshore file not empty.");
  //   } else {
  //     handleClick("Please ensure a file has been selected.", "error");
  //   }
  // }

  return (
    <div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={notificationSeverity}
            sx={{
              zIndex: 9999,
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {notificationMessage}
          </Alert>
        </Snackbar>
      </Stack>
      {showSection === "admin" ? (
        <Box>
          <Banner title={"Upload - Romario"} backgroundColor="#30AF6A" />
          <Box sx={{ padding: "32px 32px" }}>
            <p className="font-bold text-[24px]">Upload a New Project</p>
            <p className="text-[grey] pb-4 max-w-[700px]">
              Complete this form the click the 'Upload' button to add your new
              project
            </p>
            <div>
              <form>
                <Box className="flex flex-col md:flex-row pt-2 gap-4 sm:mx-auto sm:justify-center">
                  <Box
                    sx={{
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #E7E7E7", // Adjust the color as needed
                      borderRadius: "4px",
                      padding: 1,
                    }}
                    className="w-full md:w-1/3"
                  >
                    <p className="font-bold text-[#288151] text-[20px]">
                      Step One
                    </p>
                    <p className="text-[grey]">
                      Select a date on the calendar below to choose the current
                      date for the risk and presence map:
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        defaultValue={dayjs("2024-04-17")}
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    sx={{
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #E7E7E7", // Adjust the color as needed
                      borderRadius: "4px",
                      padding: 1,
                    }}
                    className="w-full md:w-1/3 flex flex-col"
                  >
                    <p className="font-bold text-[#288151] text-[20px]">
                      Step Two
                    </p>
                    <p className="text-[grey]">
                      Upload a coastline file representing the communities
                      affected by sargassum, and upload a file representing the
                      offshore and near shore communities affected.
                    </p>
                    <Box>
                      <div className="pt-4"></div>
                      <Button
                        className="w-full md:max-w-[550px]"
                        variant="contained"
                        component="label"
                        sx={{ textTransform: "none" }}
                      >
                        Coastline File (.geojson)
                        <input id="coastline_file" type="file" />
                      </Button>
                      <div className="pt-4"></div>
                      <Button
                        className="w-full md:max-w-[550px]"
                        variant="contained"
                        component="label"
                        sx={{ textTransform: "none" }}
                      >
                        Offshore & Nearshore File
                        <input id="offshore_nearshore_file" type="file" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
              <div className="pt-4 flex">
                <div onClick={runFile}>
                  <CustomButton label="Upload File" style={{ type: "dark" }} />
                </div>
                <Link to="/index">
                  <CustomButton label="Cancel" type="cancel" />
                </Link>
              </div>
            </div>

            <div className="fill-page">
              <div className="remain"></div>
            </div>
          </Box>
        </Box>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};
