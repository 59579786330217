import React, { useEffect } from "react";
import { REMOVE_SUBSCRIBER_URL } from "../apiEndPoints";
import { Box, Button } from "@mui/material";

export var SucessfulUnsubscribe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var subscriber_email = "";

  // const form = useRef();

  function getTokenFromURL(str) {
    subscriber_email = str.split("/")[2];
    console.log(subscriber_email);
  }
 
  async function unSubscribe(event) {
    event.preventDefault();

    getTokenFromURL(window.location.pathname);

    function removeSubscriber(subscriber_email) {
      fetch(REMOVE_SUBSCRIBER_URL(subscriber_email), {
        method: "DELETE",

        body: JSON.stringify({}),

        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        // Converting to JSON
        .then((response) => {
          response.json();

          if (response.ok === false) {
            alert("There was an error <error message>");
          } else {
            window.location.href = "/index";
          }
        })
        .then((data) => {});
    }

    removeSubscriber(subscriber_email);
  }

  return (
    <div>
      <Box className="" sx={{ backgroundColor: "#E6F1E3" }}>
        <Box
          className="flex flex-col h-full align-item justify-center items-center my-auto"
          sx={{ padding: "56px ", backgroundColor: "#E6F1E3" }}
        >
          <Box
            sx={{ padding: "32px", backgroundColor: "white" }}
            className="flex flex-col w-3/4 lg:w-1/2  xl:w-1/3 justify-center mx-auto"
          >
            <div className="text-center">
              <p className="font-bold">Unsubscribe from the</p>
              <p className="font-bold text-[20px] lg:text-[32px]">
                JSEAS Newsletter
              </p>
            </div>
            <p className="pb-4 text-[16px] lg:text-[18px] text-[grey]">
              Once unsubscribed, you'll stop receiving updated on the latest
              maps relating to the level of sargassum in Jamaica.
            </p>
            <form className="flex flex-col gap-4" onSubmit={unSubscribe}>
              <div className="w-full flex items-center justify-center mx-auto">
                {" "}
                <div onClick={unSubscribe}>
                  <Button
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      fontSize: "18px",
                      width: "100%",
                    }}
                    variant="contained"
                  >
                    Unsubscribe
                  </Button>
                </div>
              </div>
            </form>
            <div className="text-center pt-4">
              {" "}
              <a href="/index">← Go Back Home</a>
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
