import React, { useEffect } from "react";
import "../css/styles.css";
import { useState } from "react";
import {
  CHANGE_PASSWORD_VIA_EMAIL_URL,
  CHECK_USER_TOKEN_URL,
  DELETE_USER_TOKEN_URL,
} from "../apiEndPoints";
import { Box, TextField } from "@mui/material";
import { CustomButton } from "./components/buttons";
 
const TokenConfirmation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var user_token = "";
  var verified_user_email = "";
  const [inputValue, setInputValue] = useState("");
  console.log(inputValue)
  // const _ = require("lodash");

  function getTokenFromURL(str) {
    user_token = str.split("/")[2];
  }

  //alert(getTokenFromURL(window.location.pathname));

  function verifyToken() {
    getTokenFromURL(window.location.pathname);
    // alert("User token: " + user_token)

    fetch(CHECK_USER_TOKEN_URL(user_token), {
      // Adding method type
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        // "email": email.value ,//email.value,
        // "password": password.value //password.value
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            // console.log(res.status, res.data.title)
            //alert(res.status, res.data.title)

            if (res.data !== "Token does not exist.") {
              // alert("Data " + res.data)
              verified_user_email = res.data;
            }
          })
      )

      // Converting to JSON
      // .then((data) =>{
      //     alert(data.text)
      // })
      .then((response) => {
        if (response.ok === false) {
          // alert("Token does not exist.")
        } else {
        }
      })
      // Displaying results to console
      .then((results) => {
        results.json();
      });
  }

  verifyToken();
  async function createPassword() {
    verifyToken();
    var new_password = await document.getElementById("password_input");
    var new_password_confirmation = await document.getElementById(
      "password_input_confirm"
    );

    //fix this
    if (
      new_password.value === new_password_confirmation.value &&
      new_password.value !== "" &&
      new_password_confirmation.value !== ""
    ) {
      fetch(CHANGE_PASSWORD_VIA_EMAIL_URL(verified_user_email), {
        // Adding method type
        method: "PUT",

        // Adding body or contents to send
        body: JSON.stringify({
          password: new_password_confirmation.value,
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        // Converting to JSON
        .then((response) => {
          response.json();

          if (response.ok === false) {
            alert("There was an error <error message>");
          } else {
            alert("User password has been changed");
            new_password.value = "";
            new_password_confirmation.value = "";
            // email.value = "";

            /*****START DELETE TOKEN */

            fetch(DELETE_USER_TOKEN_URL, {
              // Adding method type
              method: "DELETE",

              // Adding body or contents to send
              body: JSON.stringify({
                token: user_token, //insert token here
              }),

              // Adding headers to the request
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              // Converting to JSON
              .then((response) => {
                response.json();

                if (response.ok === false) {
                  alert("There was an error <error message>");
                } else {
                  alert("Token has been deleted.");
                  //email.value = "";
                }
              })
              // Displaying results to console
              .then((data) => {
                // alert("User has been added with password: " + random_password)
                //alert("Data" + data.json());
              });

            /*****END DELETE TOKEN */
          }
        })
        // Displaying results to console
        .then((data) => {
          // alert("User has been added with password: " + random_password)
          //alert("Data" + data.json());
        });
    } else {
      alert(
        "Please ensure new password and confirmation of new password match."
      );
    }
  }

  return (
    <Box className="" sx={{ backgroundColor: "#E6F1E3" }}>
      <Box
        className="flex flex-col h-full align-item justify-center items-center my-auto"
        sx={{ padding: "56px ", backgroundColor: "#E6F1E3" }}
      > 
        <Box
          sx={{ padding: "32px", backgroundColor: "white" }}
          className="flex flex-col w-3/4 lg:w-1/2  xl:w-1/3 justify-center mx-auto"
        >
          <div className="log-in-section">
            <div className="log-in-box">
              <div className="log-in-box-content">
                <div className="text-center">
                  <p className="font-bold text-[20px] lg:text-[24px]">
                    Registration Confirmation Page
                  </p>
                </div>
                <form>
                  <div className="flex flex-col gap-4 pt-4">
                    <TextField
                      id="password_input"
                      placeholder="e.g. JASDKJF123"
                      type="password"
                      onChange={(event) => setInputValue(event.target.value)}
                      //onKeyDown={handleKeyDown}
                      required
                      label="New Password"
                      name="name_to"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="password_input"
                      placeholder="e.g. JASDKJF123"
                      type="password"
                      onChange={(event) => setInputValue(event.target.value)}
                      //onKeyDown={handleKeyDown}
                      required
                      label="Confirm Password"
                      name="name_to"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <br></br>
                </form>
               <div className="text-center align-center items-center">
                  <div onClick={createPassword}>
                    <CustomButton label="Create Password" style={{type:"dark"}} />
                  </div>
               </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default TokenConfirmation;
