import React, { useEffect } from "react";
import "../css/styles.css";
import { useState} from "react";
import { GET_USER_TYPE_BY_EMAIL_URL, VERIFY_USER_URL } from "../apiEndPoints";

import {
  ButtonRegularFillDiv,
  CheckBoxLabel,
  TextFieldFillDiv,
} from "./ReusableComponents";

const LogIn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var email = document.getElementById("email-input");
  var password = document.getElementById("password-input");
  const [inputValue, setInputValue] = useState("");
  console.log(inputValue)
  
  async function logUserIn(email_here) {
    email = await document.getElementById("email-input");

    password = await document.getElementById("password-input");

    localStorage.setItem("userEmail", email_here);
    fetch(GET_USER_TYPE_BY_EMAIL_URL(email_here))
      .then((response) => response.json())
      .then((data) => {
        if (data === "ADMINISTRATOR") {
          localStorage.setItem("user_type", "admin");
          window.location.href = "/index";
        }
        if (data === "DATA CONTRIBUTOR") {
          localStorage.setItem("user_type", "general");
          window.location.href = "/index";
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  async function verifyUser() {
    email = await document.getElementById("email-input");
    password = await document.getElementById("password-input");

    if (email.value != null && password.value != null) {
    }

    fetch(VERIFY_USER_URL, {
      // Adding method type
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify({
        email: email.value, //email.value,
        password: password.value, //password.value
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      // Converting to JSON
      .then((response) => {
        response.json();
        if (response.ok === false) {
          alert(
            "Your email and password combination does not match a valid account. Please try again."
          );
        } else {
          logUserIn(email.value);
        }
      })
      // Displaying results to console
      .then((data) => {
        //alert("Data" + data.json());
      });
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      verifyUser();
    }
  };

  return (
    <div>
      <div className=" flex flex-col items-center justify-center bg-[#DBF6D4]">
        <div className="">
          <div className="py-[56px]">
            <div className="flex items-center justify-center ">
              <div>
                <div className="rounded-md bg-white shadow-md p-8  w-[400px] md:w-[500px]">
                  <div className="flex justify-center font-bold py-8">
                    <h1 className="text-3xl">Log In</h1>
                  </div>
                  <form>
                    <TextFieldFillDiv
                      text="Email"
                      name="email"
                      type="email"
                      id="email-input"
                      placeholder="johndoe@email.com"
                      onChange={(event) => setInputValue(event.target.value)}
                      onKeyDown={handleKeyDown}
                    ></TextFieldFillDiv>
                    <TextFieldFillDiv
                      text="Password"
                      name="password"
                      type="password"
                      id="password-input"
                      placeholder="**********"
                      onChange={(event) => setInputValue(event.target.value)}
                      onKeyDown={handleKeyDown}
                    ></TextFieldFillDiv>
                    <CheckBoxLabel label="Remember this device"></CheckBoxLabel>
                    <ButtonRegularFillDiv
                      backgroundColor="#1A874C"
                      text="Sign In"
                      onClick={verifyUser} 
                    ></ButtonRegularFillDiv>
                  </form>
                  <div className="text-right py-4">
                    {/* <Link href="#" underline="hover">
                      Forgot password?
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
