import React, { useEffect, useState } from "react";
import { ADD_SUBSCRIBER_URL, SEND_EMAIL_URL } from "../apiEndPoints";
import { Box, Button, TextField } from "@mui/material";

const Signup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [inputValue, setInputValue] = useState("");
  console.log(inputValue);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addSubscriber(event);
    }
  };

  async function addSubscriber(event) {
    event.preventDefault();
    var subscriber_email = await document.getElementById(
      "subscriber_email_input"
    );
    var first_name = await document.getElementById("first_name_input");
    var last_name = await document.getElementById("last_name_input");

    fetch(ADD_SUBSCRIBER_URL, {
      method: "POST",
      body: JSON.stringify({
        first_name: first_name.value,
        last_name: last_name.value,
        email: subscriber_email.value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(async (response) => {
        console.log(response);
        if (response.status === 400) {
          alert(
            "Your email has already been added to JSEAS's subscription list! Please search your email for our welcome email."
          );
          const errorText = await response.text;
          try {
            const errorData = JSON.parse(errorText);
            throw new Error(errorData.error || "Unknown error");
          } catch (parseError) {
            throw new Error(errorText || "Unknown error");
          }
        }
        if (response.status === 201) {
          fetch(SEND_EMAIL_URL, {
            method: "POST",
            body: JSON.stringify({
              type: "newSubscriber",
              email_to: subscriber_email.value,
              name_to: first_name.value + " " + last_name.value,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((response) => {
              // Add return statement here
              return response.json();
            })
            .then((data) => {
              // Handle the parsed JSON data here
              console.log(data);

              // Check for success or failure
              if (data && data.ok === false) {
                console.log("There was an error: " + data.errorMessage);
              } else {
                console.log("Successful");
              }
            })
            .catch((error) => {
              // Handle errors here
              console.error("Error:", error);
              console.log("There was an error: " + error.message);
            });

          alert(
            "You have been added as a subscriber! Please check your email for your welcome email to the JSEAS's subscription list."
          );
          first_name.value = "";
          last_name.value = "";
          subscriber_email.value = "";
        }
        if (!response.ok) {
          // Handle other errors here
          alert("There was an error. Please try again.");
          throw new Error("Network response was not ok");
        }

        // Check if the response status is 400 and parse JSON

        return response.json();
      })
      .then((data) => {
        // Check if the response contains an error message
        if (data.error) {
          alert(`Error: ${data.error}`);
        } else {
          console.log("Email added");
          // Check if the response contains a success message
          // alert(data.message || "Subscriber has been added.");

        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle other errors if needed
      });
  }

  return (
    <Box className="" sx={{ backgroundColor: "#E6F1E3" }}>
      <Box
        className="flex flex-col h-full align-item justify-center items-center my-auto"
        sx={{ padding: "56px ", backgroundColor: "#E6F1E3" }}
      >
        <Box
          sx={{ padding: "32px", backgroundColor: "white" }}
          className="flex flex-col w-3/4 lg:w-1/2  xl:w-1/3 justify-center mx-auto"
        >
          <div className="text-center">
            <p className="font-bold">Subscribe to the</p>
            <p className="font-bold text-[20px] lg:text-[32px]">
              JSEAS Newsletter
            </p>
          </div>
          <p className="pb-4 text-[16px] lg:text-[18px] text-[grey]">
            Once you've finished signing up for the newsletter, you'll begin
            receiving monthly updates about the risk and presence map.
          </p>
          <form className="flex flex-col gap-4" onSubmit={addSubscriber}>
            <TextField
              id="first_name_input"
              type="name"
              placeholder="e.g. George"
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
              required
              label="First Name"
              name="name_to"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="last_name_input"
              type="name"
              placeholder="e.g. Allen"
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
              required
              label="Last Name"
              name="name_to"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="subscriber_email_input"
              type="text"
              placeholder="e.g. johndoe@email.com"
              // onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
              required
              label="Email"
              name="email"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              sx={{
                fontWeight: "bold",
                textTransform: "none",
                fontSize: "18px",
              }}
              onClick={addSubscriber}
              variant="contained"
            >
              Subscribe
            </Button>
          </form>
        </Box>
        {/* <div className="flex text-center justify-center pt-4 gap-2 text-[#0F5B32]">
          <p>Have an account?</p>
          <p className="font-bold">Admin Log In</p>
        </div>
        <p className=" text-[#0F5B32]">← Go Back Home</p> */}
      </Box>
    </Box>
  );
};

export default Signup;
