import React from "react";
import funder1 from "../imgs/funder1.png";
import funder2 from "../imgs/funder2.png";
import monaMGILogo from "../imgs/MonaGIS_MGI_Logo.png";
import sartraclogo from "../imgs/SARTRAC_Logo.png";
import JamaicaMapFinal from "./components/MyMap";
import { useState, useEffect } from "react";
import { GET_RISK_PRESENCE_DATE_URL } from "../apiEndPoints";
import { SubContentBox, HeaderContentBox } from "./components/content_box";
import { Box } from "@mui/material";
import { RiskAndPresenceMapData, months } from "../constantData";

export var Landingpage = () => {
  const [mapDate, setMapDate] = useState(null);

  useEffect(() => {
     window.scrollTo(0, 0);
    fetch(GET_RISK_PRESENCE_DATE_URL)
      .then((response) => response.json())
      .then((json) => {
        setMapDate(
          months[parseInt(json[0].map_month) - 1] + " " + json[0].map_year
        );
      });
  }, []);

  return (
    <div className="">
      <HeaderContentBox
        title="Check Sargassum Status in Your Community"
        subTitle="Visit the community bulletin board to see the sargassum status
          for your community! Filter by communities and find out more
          information of the different sectors under risk."
        buttonText="Visit Community Bulletin"
        buttonRoute="/community-bulletin"
      />
      <Box sx={{ padding: "52px 52px" }}>
        <div>
          <div className="pb-4">
            <p className="w-full font-bold text-center">
              Sargassum Beaching Risk and Presence for Jamaica
            </p>
            <p className="text-[#3F76C9] font-bold text-center pb-4 w-full">
              {mapDate}
            </p>
            <p className="text-[#565656] text-[14px] font-bold text-center pb-4 w-full">
              Zones: O - offshore, N - nearshore
            </p>
          </div>
          <div className="pb-32">
            <Box className="w-vw md:w-[100%]">
              <div className="maptest">
                <JamaicaMapFinal />
              </div>
            </Box>

            <Box className="flex flex-col text-center items-center justify-center mx-auto lg:flex-row pt-12  justify-center mx-auto gap-2">
              {RiskAndPresenceMapData.map((legend) => (
                <div>
                  <p className="font-bold w-[350px]"> {legend.title}</p>
                  {legend.data.map((items) => (
                    <Box className="flex gap-2">
                      <Box
                        sx={{
                          height: "20px",
                          width: "40px",
                          backgroundColor: items.color,
                          border: "1px solid #A8A8A8",
                        }}
                      ></Box>
                      {items.name}
                    </Box>
                  ))}
                </div>
              ))}
            </Box>
          </div>
        </div>
      </Box>

      <SubContentBox
        align="left"
        subHeader={"Want to learn more?"}
        header={"What is JSEAS?"}
        subText="The goal of the Early Advisory System for Jamaica is to analyse data from satellite imagery, field work, secondary sources and drones to produce a dynamic system that incorporates wind, and wave conditions in the predictions of Sargassum beaching alerts across Jamaica."
        buttonText="Learn More"
        buttonLink="/about"
        backgroundColor="#DBF6D4"
      />

      <SubContentBox
        align="center"
        subHeader={"Powered By"}
        otherContent={
          <div className="flex text-center mx-auto justify-center">
            <a href="http://main.monagis.com/" target="_blank" rel="noreferrer">
              <img src={monaMGILogo} alt="Mona MGI Logo"></img>
            </a>
            <a href="https://www.sartrac.org" target="_blank" rel="noreferrer">
              <img src={sartraclogo} alt="SARTRAC Logo"></img>
            </a>
          </div>
        }
      />
      <SubContentBox
        align="left"
        subHeader={"Keep in touch!"}
        header={"Subscribe to Our Newsletter"}
        subText="Join JSEAS for real-time Sargassum beaching alerts in Jamaica. Get accurate predictions through satellite imagery, fieldwork, and drones. Click 'Subscribe' now!"
        buttonText="Subscribe"
        buttonLink="/sign-up"
        backgroundColor="#DBF6D4"
      />
      <SubContentBox
        align="center"
        subHeader={"A big thank you to our funders"}
        otherContent={
          <div className="flex text-center mx-auto justify-center pt-4">
            <a
              href="https://www.ukri.org/councils/esrc/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={funder1} alt=""></img>
            </a>
            <a
              href="https://www.ukri.org/what-we-do/browse-our-areas-of-investment-and-support/global-challenges-research-fund/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={funder2} alt=""></img>
            </a>
          </div>
        }
      />
    </div>
  );
};
