import React, { useEffect } from "react";
import shoreline_beaching_risk from "../imgs/sectors_images/ShorelineBeachingRisk_Icon2.png";
import floating_mat_risk from "../imgs/sectors_images/FloatingMatRisk_Icon2.png";
import adaptive_strategies from "../imgs/sectors_images/AdaptiveStrategies_Icon2.png";

import fishing_interests_title_picture from "../imgs/sectors_images/FishingInterestsIcon_2.png";
import coastal_leisure_interests_title_picture from "../imgs/sectors_images/CoastalLeisureIcon_2.png";
import marine_navigation_interests_title_picture from "../imgs/sectors_images/MarineNavigationIcon_2.png";
import tourism_interests_title_picture from "../imgs/sectors_images/TourismIcon_2.png";
import critical_infrastructure_picture from "../imgs/sectors_images/CriticalInfrastructureSector_Icon2.png";
import business_infrastructure_picture from "../imgs/sectors_images/business_infrastructure_icon.png";
import { useRef } from "react";
import { Banner } from "./components/banner";
import { Box, Button } from "@mui/material";

export var SectorsUnderRisk = () => {
    window.scrollTo(0, 0);
  const coastal_leisure_section = useRef(null);
  const fishing_section = useRef(null);
  const tourism_section = useRef(null);
  const marine_navigation_section = useRef(null);
  const critical_infrastructure_section = useRef(null);
  const business_infrastructure_section = useRef(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const sectionIdentifier = currentUrl.split("#")[1];

    if (sectionIdentifier) {
      const element = document.getElementById(sectionIdentifier);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  function scrollToCoastalLeisureSection() {
    coastal_leisure_section.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToFishingSection() {
    fishing_section.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToTourismSection() {
    tourism_section.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToMarineNavigationSection() {
    marine_navigation_section.current.scrollIntoView({ behavior: "smooth" });
  }
  function scrollToCriticalInfrastructureSection() {
    critical_infrastructure_section.current.scrollIntoView({
      behavior: "smooth",
    });
  }
  function scrollToBusinessSection() {
    business_infrastructure_section.current.scrollIntoView({
      behavior: "smooth",
    });
  }

  var sectors_data = [
    {
      sectionTitle: "Fishing Interests",
      icon: fishing_interests_title_picture,
      imgAlt: "Icon for Fishing Interests",
      ref: fishing_section,
      id: "fishing-interests",
      onClick: scrollToFishingSection,
      backgroundColor: "#E6F1E3",
      content: [
        {
          icon: shoreline_beaching_risk,
          title: "Shoreline Beaching Risk",
          points: [
            "Difficulty launching boats",
            "Trapped and decomposing marine organisms on fishing beach",
            "Pungent scent on fishing beach",
            " Equipment entanglement/damage",
            "Respiratory and dermatology health risk from decomposing Sargassum",
          ],
        },
        {
          icon: floating_mat_risk,
          title: "Floating Mat Risk",
          points: [
            "Engine entanglement",
            "Equipment entanglement/damage",
            "Loss of a variety of fishing areas and species",
          ],
        },
        {
          icon: adaptive_strategies,
          title: "Apdaptive Strategies",
          points: [
            "Large floating mats serve as habitat for commercially important fish species such as Mahi Mahi. Fisherfolk may target these species",
            "Change fishing method. Certain methods are more prone to damage from Sargassum such as use of nets and trolling",
            "Detangle Sargassum from engine as soon as Sargassum becomes trapped",
            "Change fishing location if possible",
            "Engage in manual cleanup of beach",
            "Deployment of barrier along the extent of fishing beach",
          ],
        },
      ],
    },
    {
      sectionTitle: "Tourism Interests",
      icon: tourism_interests_title_picture,
      imgAlt: "Icon for Tourism Interests",
      onClick: scrollToTourismSection,
      id: "tourism-interests",
      ref: tourism_section,
      backgroundColor: "white",
      content: [
        {
          icon: shoreline_beaching_risk,
          title: "Shoreline Beaching Risk",
          points: [
            "Reduced aesthetic of beach",
            "Solid waste and decomposing marine organisms on beach",
            "Reduced swimming area",
            "Restricted beached recreation activities",
            "Respiratory and dermatology health risk from decomposing Sargassum",
            "Pungent scent on beach",
          ],
        },
        {
          icon: floating_mat_risk,
          title: "Floating Mat Risk",
          points: [
            "Restricted swimming area",
            "Restricted aqua-sports",
            "Engine damage to watercraft",
          ],
        },
        {
          icon: adaptive_strategies,
          title: "Adaptive Strategies",
          points: [
            "Deploy barrier along the length of beach",
            "Engage in manual cleanup of beach",
            "Distribute safety pamphlets to tourists",
          ],
        },
      ],
    },
    {
      sectionTitle: "Marine Navigation Interests",
      icon: marine_navigation_interests_title_picture,
      imgAlt: "Icon for Marine Navigation Interests",
      ref: marine_navigation_section,
      id: "marine-navigation",
      onClick: scrollToMarineNavigationSection,
      backgroundColor: "#E6F1E3",
      content: [
        {
          icon: shoreline_beaching_risk,
          title: "Shoreline Beaching Risk",
          points: [
            "Difficulty accessing and enjoying coastal recreational areas due to the accumulation of Sargassum along the shoreline.",
            "The presence of trapped and decomposing marine organisms on the beach, creating an unpleasant environment for beachgoers.",
            "Pungent scent emitted by decomposing Sargassum, affecting the overall beach experience for visitors.",
            "Potential equipment entanglement and damage for water sports enthusiasts, such as swimmers, surfers, recreational fishermen and kayakers.",
            "Increased respiratory and dermatology health risks for beachgoers due to the decomposition of Sargassum. Health risk also for pregnant women as prolonged exposure to Sargassum has been associated with increased incidences of preeclampsia.",
          ],
        },
        {
          icon: floating_mat_risk,
          title: "Floating Mat Risk",
          points: [
            "The risk of engine entanglement for motorized watercraft, such as boats and jet skis, due to the presence of floating mats of Sargassum.",
            "Equipment entanglement and damage for activities like water skiing, paddleboarding, and snorkeling, which may hinder the overall recreational experience.",
            "Loss of a variety of recreational areas and species diversity as floating mats of Sargassum restrict access to popular diving and snorkeling spots, impacting underwater exploration.",
          ],
        },
        {
          icon: adaptive_strategies,
          title: "Adaptive Strategies",
          points: [
            "Detangle Sargassum from engine as soon as Sargassum becomes trapped",
            "Avoid navigating vessel through large mats of Sargassum",
          ], 
        },
      ],
    },
    {
      sectionTitle: "Coastal Leisure Interests",
      icon: coastal_leisure_interests_title_picture,
      imgAlt: "Icon for Coastal Leisure Interests",
      ref: coastal_leisure_section,
      id: "leisure",
      onClick: scrollToCoastalLeisureSection,
      backgroundColor: "white",
      content: [
        {
          icon: shoreline_beaching_risk,
          title: "Shoreline Beaching Risk",
          points: [
            "Difficulty accessing and enjoying coastal recreational areas due to the accumulation of Sargassum along the shoreline.",
            "The presence of trapped and decomposing marine organisms on the beach, creating an unpleasant environment for beachgoers.",
            "Pungent scent emitted by decomposing Sargassum, affecting the overall beach experience for visitors.",
            "Potential equipment entanglement and damage for water sports enthusiasts, such as swimmers, surfers, recreational fishermen, and kayakers.",
            "Increased respiratory and dermatology health risks for beachgoers due to the decomposition of Sargassum. Health risk also for pregnant women as prolonged exposure to Sargassum has been associated with increased incidences of preeclampsia.",
          ],
        },
        {
          icon: floating_mat_risk,
          title: "Floating Mat Risk",
          points: [
            "The risk of engine entanglement for motorized watercraft, such as boats and jet skis, due to the presence of floating mats of Sargassum.",
            "Equipment entanglement and damage for activities like water skiing, paddleboarding, and snorkeling, which may hinder the overall recreational experience.",
            "Loss of a variety of recreational areas and species diversity as floating mats of Sargassum restrict access to popular diving and snorkeling spots, impacting underwater exploration.",
          ],
        },
        {
          icon: adaptive_strategies,
          title: "Adaptive Strategies",
          points: [
            "Be aware of areas with heavy Sargassum accumulation and adjust leisure activities accordingly to minimize disruptions and risks. This can be achieved by following JSEAS updates!",
            "Consider alternative water sports or recreational activities that are less prone to equipment entanglement or damage from Sargassum, such as beach volleyball.",
            "Explore alternative coastal areas or beaches that are less affected by Sargassum accumulation for leisure activities.",
            "The state may consider the deployment of barriers or nets along the extent of the recreational beach to prevent or minimize Sargassum accumulation and floating mat formation, improving the overall leisure experience for visitors.",
          ],
        },
      ],
    },
    {
      sectionTitle: "Critical Infrastructure Sector",
      icon: critical_infrastructure_picture,
      imgAlt: "Icon for Critical Infrastructure Sector",
      ref: critical_infrastructure_section,
      id: "critical-infrastructure",
      onClick: scrollToCriticalInfrastructureSection,
      backgroundColor: "#E6F1E3",
      content: [
        {
          icon: shoreline_beaching_risk,
          title: "Shoreline Beaching Risk",
          points: [
            "Trapped and decomposing marine organisms on the premises, creating an unsightly and potentially unhygienic environment for employees and visitors.",
            "Unpleasant odor emitted by decomposing Sargassum, which can negatively impact the overall experience and reputation of critical infrastructure facilities.",
            "Potential equipment entanglement and damage for maintenance crews and security personnel, hindering their ability to perform their duties effectively.",
            "Increased health risks for employees and visitors, including respiratory and dermatological issues. Health risk also for pregnant women as prolonged exposure to Sargassum has been associated with increased incidences of preeclampsia.",
          ],
        },
        {
          icon: floating_mat_risk,
          title: "Floating Mat Risk",
          points: [
            "Equipment entanglement and damage for water transportation services, potentially disrupting the smooth operation of critical infrastructure facilities.",
            "Restricted access to coastal areas for emergency response teams and personnel, limiting their ability to provide timely assistance during coastal incidents.",
          ],
        },
        {
          icon: adaptive_strategies,
          title: "Adaptive Strategies",
          points: [
            "Implementation of preventive measures, such as installing barriers or nets, to minimize the accumulation of Sargassum along the shoreline near critical infrastructure facilities.",
            "Coordination with local authorities and environmental agencies to develop effective Sargassum management plans tailored to the specific needs of critical infrastructure facilities.",
            "Provision of appropriate personal protective equipment (PPE) to employees who may come into contact with decomposing Sargassum, mitigating health risks.",
            "Development of alternative access routes or transportation methods during periods of high Sargassum accumulation to ensure the smooth operation and functionality of critical infrastructure facilities.",
          ],
        },
      ],
    },
    {
      sectionTitle: "Business Infrastructure",
      id: "business-infrastructure",
      imgAlt: "Icon for Business Infrastructure",
      icon: business_infrastructure_picture,
      onClick: scrollToBusinessSection,
      ref: business_infrastructure_section,
      backgroundColor: "white",
      content: [
        {
          icon: shoreline_beaching_risk,
          title: "Shoreline Beaching Risk",
          points: [
            "Trapped and decomposing marine organisms on the premises, creating an unappealing and potentially unhygienic environment for employees and customers.",
            "Potential equipment entanglement and damage for businesses utilizing water-based transportation or outdoor facilities, hindering their operations and customer service.",
            "Increased health risks for employees and customers, including respiratory and dermatological issues. Health risk also for pregnant women as prolonged exposure to Sargassum has been associated with increased incidences of preeclampsia.",
          ],
        },
        {
          icon: floating_mat_risk,
          title: "Floating Mat Risk",
          points: [
            "Equipment entanglement and damage for businesses offering water sports or recreational activities near the coastline, potentially impacting customer satisfaction and safety.",
            "Restricted access to coastal areas for businesses relying on beachfront or waterfront locations, affecting their ability to attract customers and generate revenue.",
          ],
        },
        {
          icon: adaptive_strategies,
          title: "Adaptive Strategies",
          points: [
            "Regular monitoring and **state-approved** clearance of Sargassum accumulation near business infrastructure facilities to ensure uninterrupted access for employees and customers.",
            "Implementation of preventive measures, such as installing barriers or nets, to minimize the accumulation of Sargassum along the shoreline near business infrastructure facilities.",
            "Collaboration with local authorities and environmental agencies to develop effective Sargassum management plans tailored to the specific needs of businesses.",
            "Provision of appropriate personal protective equipment (PPE) to employees who may come into contact with decomposing Sargassum, mitigating health risks.",
            "Diversification of business activities or offerings to minimize the impact of Sargassum-related disruptions, such as expanding online services or indoor attractions during periods of high Sargassum accumulation.",
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <Banner title={"Sectors Under Risk"} backgroundColor="#30AF6A" />
      <div className="bodyC">
        <Box sx={{ padding: "56px 32px" }}>
          <p className="pb-2 text-center">
            Select one of the sectors to quickly scroll to the section:
          </p>
          <div className="flex flex-wrap items-center justify-center gap-4">
            {sectors_data.map((sectors, index) => (
              <Button
                key={index}
                sx={{
                  backgroundColor: "white",
                  color: "#7D7D7D",
                  fontWeight: "500",
                  fontSize: 12,
                  textTransform: "none",
                  border: "1px solid darkGrey",
                  boxShadow: "none",
                }}
                variant="contained"
                onClick={sectors.onClick}
              >
                {sectors.sectionTitle}
              </Button>
            ))}
          </div>
        </Box>

        {sectors_data.map((sectors) => (
          <Box
            key={sectors.sectionTitle}
            sx={{
              backgroundColor: sectors.backgroundColor,
              padding: "32px 56px",
            }}
            ref={sectors.ref}
            id={sectors.id ? sectors.id : null}
          >
            <img
              className="flex items-center justify-center mx-auto"
              alt={sectors.imgAlt}
              src={sectors.icon}
            />
            <p className="flex items-center justify-center font-bold text-lg pb-4">
              {sectors.sectionTitle}
            </p>
            <Box className="flex flex-wrap gap-4">
              {sectors.content.map((content) => (
                <Box
                  key={content.title}
                  sx={{
                    backgroundColor: "white",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    maxWidth: "300px",
                    width: "100%",
                    "@media (min-width: 600px)": {
                      width: "calc(33.3333% - 16px)",
                    },
                  }}
                  className="mx-auto p-4 border border-gray-200 rounded-md"
                >
                  <div>
                    <img
                      className="flex items-center justify-center mx-auto"
                      src={content.icon}
                      alt={content.title}
                    />
                    <p className="font-bold pt-4 text-center">
                      {content.title}
                    </p>
                    <div>
                      {content.points.map((point, index) => (
                        <p key={index}>{point}</p>
                      ))}
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </div>
    </div>
  );
};
