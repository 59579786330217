import React from "react";
//import JamaicaMap from '../components/MyMap';
import JamaicaMap from "./components/MyMap";
import { useState, useEffect } from "react";
import {
  GET_RISK_PRESENCE_DATE_URL,
  UPDATE_ZONES_NEARSHORE_URL,
  UPDATE_ZONES_OFFSHORE_URL,
} from "../apiEndPoints";
import { Banner } from "./components/banner";
import { Box } from "@mui/material";
import { RiskAndPresenceMapData } from "../constantData";
import { months } from "../constantData";

export var RiskPresence = () => {
  const [mapDate, setMapDate] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues2, setSelectedValues2] = useState([]);
  useEffect(() => {
      window.scrollTo(0, 0);

    fetch(GET_RISK_PRESENCE_DATE_URL)
      .then((response) => response.json())
      .then((json) => {
        setMapDate(
          months[parseInt(json[0].map_month) - 1] + " " + json[0].map_year
        );
      });
  }, []);

  const handleSelectChange = (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedValues(selectedValues);
  };

  const handleSelectChange2 = (event) => {
    const options = event.target.options;
    const selectedValues2 = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues2.push(options[i].value);
      }
    }
    setSelectedValues2(selectedValues2);
  };

  async function changeColor() {
    var color = await document.getElementById("input").value;
    var bound1Value = await document.getElementById("bound1").value;
    var bound2Value = await document.getElementById("bound2").value;

    if (bound1Value !== "NONE") {
      for (var i = 0; i < selectedValues.length; i++) {
        console.log(selectedValues[i]);
        // POST request using fetch()
        fetch(UPDATE_ZONES_NEARSHORE_URL(selectedValues[i]), {
          // Adding method type
          method: "PUT",

          // Adding body or contents to send
          body: JSON.stringify({
            color: color,
          }),

          // Adding headers to the request
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          // Converting to JSON
          .then((response) => {
            response.json();

            if (response.ok === false) {
              alert("There was an error <error message>");
            } else {
              console.log("Update has been made");
            }
          })
          // Displaying results to console
          .then((data) => {});
      }
    }

    if (bound2Value != "NONE") {
      for (var i = 0; i < selectedValues2.length; i++) {
        console.log(selectedValues2[i]);
        // POST request using fetch()

        fetch(UPDATE_ZONES_OFFSHORE_URL(selectedValues2[i]), {
          // Adding method type
          method: "PUT",

          // Adding body or contents to send
          body: JSON.stringify({
            color: color,
          }),

          // Adding headers to the request
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          // Converting to JSON
          .then((response) => {
            response.json();

            if (response.ok === false) {
              alert("There was an error <error message>");
            } else {
              console.log("Update has been made");
            }
          })
          // Displaying results to console
          .then((data) => {});
      }
    }

    alert("Update has been made");
    window.location.reload();
  }

  return (
    <div>
      <Banner title={"Risk and Presence Map"} backgroundColor="#585858" />
      <Box sx={{ padding: "52px 52px" }}>
        <div className="pb-4">
          <p className="w-full font-bold text-center">
            Sargassum Beaching Risk and Presence for Jamaica
          </p>
          <p className="text-[#3F76C9] font-bold text-center pb-4 w-full">
            {mapDate}
          </p>
          <p className="text-[#565656] text-[14px] font-bold text-center pb-4 w-full">
            Zones: O - offshore, N - nearshore
          </p>
        </div>

        <div className="">
          <Box className="">
            <div className="sub-page-content">
              <div className="hide-mobile">
                <div>
                  <JamaicaMap />
                </div>

                <Box className="flex flex-col text-center items-center justify-center mx-auto lg:flex-row pt-12  justify-center mx-auto gap-2">
                  {RiskAndPresenceMapData.map((legend) => (
                    <div>
                      <p className="font-bold w-[350px]"> {legend.title}</p>
                      {legend.data.map((items) => (
                        <Box className="flex gap-2">
                          <Box
                            sx={{
                              height: "20px",
                              width: "40px",
                              backgroundColor: items.color,
                              border: "1px solid #A8A8A8",
                            }}
                          ></Box>
                          {items.name}
                        </Box>
                      ))}
                    </div>
                  ))}
                </Box>
              </div>
            </div>
          </Box>
        </div>
      </Box>
    </div>
  );
};
