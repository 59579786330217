import React from "react";
import { Box } from "@mui/system";

export var Banner = function ({
  title,
  backgroundColor
}) {
  return (
    <Box
      sx={{
        padding: "16px 56px",
        backgroundColor: backgroundColor,
        textAlign: "center",
        color: "white"
      }}
    >
     <p className="font-bold text-[24px]"> {title}</p>
    </Box>
  );
};
