import { Button } from "@mui/material";
import React, {useEffect} from "react";
import BackgroundImage from "../../imgs/headerbackgroundimage.png";
import { Box } from "@mui/system";
import { CustomButton } from "./buttons";
import { Link } from "react-router-dom";

export var HeaderContentBox = function ({ title, subTitle, buttonText, buttonRoute }) {
  const containerStyle = {
    position: "relative",
    background: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white", // Text color on top of the overlay
    textAlign: "center",
    py: 16,
    px: 16,
  };

  const overlayStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(6, 54, 28, 0.80)", // Adjust the overlay color and opacity
    zIndex: 0,
  };

  return (
    <div style={containerStyle}>
      <div className="text-center py-16 px-16 relative z-10">
        <p className="text-[24px] md:text-[34px] font-bold text-white">
          {title}
        </p>
        <div className="py-1"></div>
        <p className="text-gray-300 mx-auto flex text-center align-center justify-center md:w-[600px]">
          {subTitle}
        </p>
        <div className="py-4"></div>
        <Link to={buttonRoute}>
          <Button
            sx={{
              color: "#30AF6A",
              fontWeight: "bold",
              backgroundColor: "#F1FFED",
              textTransform: "none",
              fontSize: 16,
            }}
            variant="contained"
          >
            {buttonText}
          </Button>
        </Link>
      </div>
      <div style={overlayStyle}></div>
    </div>
  );
};

export var SubContentBox = function ({
  subHeader,
  header,
  subText,
  buttonText,
  buttonLink,
  backgroundColor,
  align,
  otherContent,
}) {
  return (
    <Box
      sx={{
        padding: "32px 56px",
        backgroundColor: backgroundColor,
        textAlign: align,
      }}
    >
      <div>
        {subHeader && (
          <p className="text-[#12932E]">
            {/* // <p className={align === "left" ? "text-[#12932E]" : "text-[#12932E] font-bold pb-4"}> */}
            {subHeader}
          </p>
        )}
        {header && <p className="font-bold text-[24px]">{header}</p>}{" "}
        {subText && <p className="max-w-[700px]">{subText}</p>}
        {otherContent}
        {buttonText && (
          <div>
            <div className="py-2"></div>
            <Link to={buttonLink}>
              <CustomButton
                style="light"
                link="/index"
                type="link"
                label={buttonText}
              />
            </Link>
          </div>
        )}
      </div>
    </Box>
  );
};
