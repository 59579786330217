import React, { useState, useEffect } from "react";
import {
  REMOVE_USER_URL,
  UPDATE_USER_STATUS_URL,
  VIEW_USERS_URL,
} from "../apiEndPoints"; 
import { Banner } from "./components/banner";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NotFoundPage } from "./404Page";
import { loadPageUserType } from "../functions";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export var ViewUsers = () => {
  const [posts, setPosts] = useState([]);
  const [showSection, setShowSection] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSeverity, setNotificationSeverity] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(false); // New state variable

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const reloadSection = () => {
    // Increment the key to trigger a re-render
    setReloadKey((prevKey) => prevKey + 1);
    setReloadFlag((prevFlag) => !prevFlag);
  };

  const handleClick = (message, severity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fetchPosts = async () => {
    setShowSection(loadPageUserType());
    fetch(VIEW_USERS_URL, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, [reloadFlag]);

  function user_status_option(status) {
    if (status === "ACTIVE") {
      return <LockOpenIcon />;
    }
    if (status === "LOCKED") {
      return <LockIcon />;
    }
  }

  function deleteUser(user_email) {
    // alert(user_email);
    console.log(user_email.user_id);
    if (
      window.confirm("Are you sure you would like this user to be removed?") ===
      true
    ) {
      // alert("User has been removed from the system.");
      // displayAlertNotification("User has been removed from the system");
      console.log("User has been removed from the system.");
      fetch(REMOVE_USER_URL(user_email.user_id), {
        // Adding method type
        method: "DELETE",

        // Adding body or contents to send
        body: JSON.stringify({
          // "email": email.value
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        // Converting to JSON
        .then((response) => {
          response.json();

          if (response.ok === false) {
            alert("There was an error <error message>");
          } else {
            handleClick("User has been removed from the system.", "success");
            reloadSection();
          }
        })
        // Displaying results to console
        .then((data) => {});
    } else {
      console.log("User has not been removed from the system.");
    }
  }

  function status_button(user_info) {
    const user_status_update =
      user_info.user_status === "LOCKED" ? "ACTIVE" : "LOCKED";

    fetch(UPDATE_USER_STATUS_URL, {
      // Adding method type
      method: "PUT",

      // Adding body or contents to send
      body: JSON.stringify({
        // "email": email.value
        user_email: user_info.user_email,
        user_status: user_status_update,
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      // Converting to JSON
      .then((response) => {
        response.json();

        if (response.ok === false) {
          alert("There was an error <error message>");
        } else {
          handleClick("User status has been changed.", "success");
          reloadSection();
          // window.location.reload();
        }
      })
      // Displaying results to console
      .then((data) => {});
  }

  return (
    <div>
      {(showSection === "admin") | (showSection === "general") ? (
        <Box>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={notificationSeverity}
                sx={{
                  zIndex: 9999,
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {notificationMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Banner title={"View Users"} backgroundColor="#30AF6A" />
          <Box sx={{ padding: "32px 32px" }}>
            <p className="font-bold text-[24px]">View Users</p>
            <p className="text-[grey] pb-4 max-w-[700px]">
              Enter the details below necessary to create a new user. Once
              created, a default password will be sent to user after which they
              may log in an change.
            </p>

            <TableContainer
              key={reloadKey}
              sx={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              component={Paper}
            >
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Email
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      User Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Status
                    </TableCell>
                    {showSection === "admin" ? (
                      <TableCell sx={{ fontWeight: "bold" }} align="center">
                        Options
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {posts.map((row) => (
                    <TableRow
                      // key={row.first_name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.first_name + " "}
                        {row.last_name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="center">{row.user_type}</TableCell>
                      <TableCell align="center">{row.status_type}</TableCell>
                      {showSection === "admin" ? (
                        <TableCell align="center">
                          <ul className="flex justify-center mx-auto">
                            <li>
                              <div
                                onClick={() =>
                                  status_button({
                                    user_email: row.email,
                                    user_status: row.status_type,
                                  })
                                }
                              >
                                {user_status_option(row.status_type)}
                              </div>
                            </li>
                            <li>
                              {" "}
                              {/*key={post.user_id}>*/}
                              <Link
                              to={"/edit-user/"+row.user_id}
                               
                              >
                                <EditIcon />
                              </Link>
                            </li>
                            <li key="{post.user_id}">
                              <div
                                onClick={() =>
                                  deleteUser({ user_id: row.email })
                                }
                              >
                                <DeleteIcon />
                              </div>
                            </li>
                          </ul>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <div id="alert-notification" className="alert-notification-invisible">
            {/* <p>Test</p> */}
          </div>
        </Box>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};
