//Function to load user type from local storage
export const loadPageUserType = () => {
  if (localStorage.getItem("user_type") === "general") {
    return "general";
  } else if (localStorage.getItem("user_type") === "admin") {
    return "admin";
  } else {
    localStorage.setItem("user_type", "none");
    return "none";
  }
};