import React, { useRef, useState, useEffect } from "react";
import { GET_USER_BY_ID_URL, UPDATE_USER_URL } from "../apiEndPoints";
import { Banner } from "./components/banner";
import { Box, MenuItem, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { CustomButton } from "./components/buttons";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { loadPageUserType } from "../functions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export var EditUser = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const form = useRef();
  const [showSection, setShowSection] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSeverity, setNotificationSeverity] = useState("");

  const handleClick = (message, severity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setShowSection(loadPageUserType());
  }, []);

  const handleChange = (event) => {
    setUserType(event.target.value);
  };
  var user_id = "";
  function getUserIDFromURL() {
    //return str.split('/')[2];
    user_id = window.location.pathname.split("/")[2];
    //alert(user_id)
  }

  getUserIDFromURL();

  const [firstName, setFirstName] = useState([""]);
  const [lastName, setLastName] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [userType, setUserType] = useState([]);

  useEffect(() => {
    fetch(GET_USER_BY_ID_URL(user_id))
      .then((response) => response.json())
      .then((data) => {
        console.log(data[0]);
        // setEditUser(data[0]);
        setFirstName(data[0].first_name);
        setLastName(data[0].last_name);
        setUserEmail(data[0].email);
        setUserType(data[0].user_type);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [user_id]);

  async function editUserButton() {
    console.log("Works");
    const user_type = await document.querySelectorAll(
      'input[name="user-type"]'
    );
    var user_type_value = "";

    for (const f of user_type) {
      if (f.checked) {
        user_type_value = f.value;
        // console.log(f.value)
        console.log(user_type_value);
      }
    }

    // alert("Here is the random_token: " + random_token)

    // POST request using fetch()
    fetch(UPDATE_USER_URL(user_id), {
      // Adding method type
      method: "PUT",

      // Adding body or contents to send
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: userEmail,
        user_type: userType,
      }),

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      // Converting to JSON
      .then((response) => {
        response.json();

        if (response.ok === false) {
          alert("There was an error <error message>");
        } else {
          handleClick("The user has successfully been edited.", "success");
          // alert("User has been edited.");
          // window.location.href = "/view-users";
        }
      })
      // Displaying results to console
      .then((data) => {});
  }

  return (
    <div>
      {showSection === "admin" ? (
        <div>
          <Banner title="Edit User" backgroundColor="#30AF6A" />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={notificationSeverity}
                sx={{
                  zIndex: 9999,
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {notificationMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Box sx={{ padding: "32px 32px" }}>
            <p className="font-bold text-[24px]">Edit User</p>
            <p className="text-[grey] pb-4 max-w-[700px]">
              Enter the details below necessary to create a new user. Once
              created, a default password will be sent to user after which they
              may log in an change.
            </p>
            <form className="flex flex-col gap-4 md:w-[450px]" ref={form}>
              <TextField
                required
                label="First Name"
                id="first_name_input"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name="name_to"
                type="text"
                placeholder="e.g. John"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                required
                label="Last Name"
                id="last_name_input"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="e.g. Doe"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                required
                label="Email"
                id="email_input"
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                name="email_to"
                placeholder="e.g. johndoe@email.com"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={userType}
                placeholder="-Select User Type-"
                name="user-type"
                id="user-type"
                onChange={handleChange}
                select
                label="User Type"
                className="user-type-dropdown"
                InputLabelProps={{
                  shrink: true,
                  style: { color: "grey" },
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) =>
                    value === "" ? "-Select User Type-" : value,
                }}
              >
                <MenuItem key={1} value="DATA CONTRIBUTOR">
                  Data Contributor
                </MenuItem>
                <MenuItem key={2} value="ADMINISTRATOR">
                  Administrator
                </MenuItem>
              </TextField>
            </form>
            <div className="pt-4 flex">
              <div onClick={editUserButton}>
                {/* <a onClick={editUserButton}> */}
                <CustomButton label="Edit User" style={{ type: "dark" }} />
                {/* </a> */}{" "}
              </div>
              <Link to="/view-users">
                <CustomButton label="Cancel" type="cancel" />
              </Link>
            </div>
          </Box>
        </div>
      ) : null}
    </div>
  );
};
