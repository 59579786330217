import React from "react";
import {
  GET_RISK_PRESENCE_MAP_DATA_URL,
  GET_RISK_PRESENCE_DATE_URL
} from "../apiEndPoints";

import mini_graphic_critical_infrastructure from "../imgs/mini_graphic_critical_infrastructure.png";
import mini_graphic_fishing from "../imgs/mini_graphic_fishing.png";
import mini_graphic_leisure from "../imgs/mini_graphic_leisure.png";
import mini_graphic_marine_navigation from "../imgs/mini_graphic_marine_navigation.png";
import mini_graphic_alternative_use_of_sargassum from "../imgs/mini_graphic_alternative_use_of_sargassum.png";
import mini_graphic_offshore_presence from "../imgs/mini_graphic_offshore_presence.png";
import mini_graphic_tourism from "../imgs/mini_graphic_tourism.png";
import mini_graphic_business_infrastructure from "../imgs/mini_graphic_business_infrastructure.png";
import risk_none_icon from "../imgs/risk_image_icon_none_grey.png";
import risk_minor_icon from "../imgs/risk_image_icon_orange_medium.png";
import risk_major_icon from "../imgs/risk_image_icon_red_high.png";

import zone_icon from "../imgs/zone_icon.png";
import { useState, useEffect } from "react";
import { Banner } from "./components/banner";
import { Box, Grid } from "@mui/material";
import offShoreIcon from "../imgs/assets/offshoreRiskIcon.png";
import specificCoastalActivitiesIcon from "../imgs/assets/specificCoastalActivitiesIcon.png";
import beachingRiskIcon from "../imgs/assets/beachingRiskIcon.png";
import nearshoreRiskIcon from "../imgs/assets/nearshoreRiskIcon.png";
import transformationalOpportunitiesIcon from "../imgs/assets/transformationalOpportunitiesIcon.png";
import { months } from "../constantData";

export var NationalBulletin = () => {
  const [mapDate, setMapDate] = useState([null]);
  const [mapData, setMapData] = useState([null]);
  const [communitiesList, setCommunitiesList] = useState([]);
  const [offShoreRiskList, setOffShoreRiskList] = useState(null);
  const [nearShoreRiskList, setNearShoreRiskList] = useState(null);
  const [fishingStatus, setFishingStatus] = useState(0);
  const [tourismStatus, setTourismStatus] = useState(0);
  const [marineNavigationStatus, setMarineNavigationStatus] = useState(0);
  const [leisureStatus, setLeisureStatus] = useState(0);
  const [criticalInfrastructureStatus, setCriticalInfrastructureStatus] =
    useState(0);
  const [businessInfrastructureStatus, setBusinessInfrastructureStatus] =
    useState(0);
  const [totalSocialV, setTotalSocialV] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var sectors_data = [
    {
      sectionTitle: "FISHING",
      icon: mini_graphic_fishing,
      id: "fishing-interests",
      status: fishingStatus,
    },
    {
      sectionTitle: "TOURISM",
      icon: mini_graphic_tourism,
      id: "tourism-interests",
      status: tourismStatus,
    },
    {
      sectionTitle: "MARINE NAVIGATION",
      icon: mini_graphic_marine_navigation,
      id: "marine-navigation",
      status: marineNavigationStatus,
    },
    {
      sectionTitle: "LEISURE",
      icon: mini_graphic_leisure,
      id: "leisure",
      status: leisureStatus,
    },
    {
      sectionTitle: "CRITICAL INFRASTRUCTURE",
      icon: mini_graphic_critical_infrastructure,
      id: "critical-infrastructure",
      status: criticalInfrastructureStatus,
    },
    {
      sectionTitle: "BUSINESS INFRASTRUCTURE",
      icon: mini_graphic_business_infrastructure,
      id: "business-infrastructure",
      status: businessInfrastructureStatus,
    },
  ];

  useEffect(() => {
    if (mapDate.length === 0 || (mapDate.length === 1 && mapDate[0] === null)) {
      fetch(GET_RISK_PRESENCE_DATE_URL)
        .then((response) => response.json())
        .then((json) => {
          setMapDate(
            months[parseInt(json[0].map_month) - 1] + " " + json[0].map_year
          );
        });
    }

    if (mapData.length === 0 || (mapData.length === 1 && mapData[0] === null)) {
      fetch(GET_RISK_PRESENCE_MAP_DATA_URL)
        .then((response) => response.json())
        .then((json) => {
          setMapData(json);
        });
    }
  }, [mapDate, mapData]);

  useEffect(() => {
    loadNationalBulletinInfo();
  }, [mapDate, mapData]);

  function loadNationalBulletinInfo() {
    var tempOffShoreRiskList = [];
    var tempNearShoreRiskList = [];
    var searchMonth;
    var searchYear;
    var searchby;
    var tempCommunitesList = [];
    if (typeof mapDate === "string") {
      const dateParts = mapDate.split(" ");
      if (dateParts.length === 2) {
        [searchMonth, searchYear] = dateParts;
      } else {
        // Handle the case where mapDate doesn't have the expected format
        console.error("Invalid mapDate format:", mapDate);
      }
    } else {
      // Handle the case where mapDate is not a string
      console.error("mapDate is not a string:", mapDate);
    }

    if (months.indexOf(searchMonth) + 1 < 10) {
      searchby = searchYear + "_" + "0" + (months.indexOf(searchMonth) + 1);
    } else {
      searchby = searchYear + "_" + (months.indexOf(searchMonth) + 1);
    }

    if (
      (mapData.length === 0 ||
        (mapData.length === 1 && mapData[0] === null)) === false
    ) {
      mapData.features.forEach((feature) => {
        const {
          OBJECTID_2,
          TotSocialV,
          Tourism,
          CritInf,
          CoaLei,
          BusInf,
          MarNav,
          Fishing,
          Offs_ID,
          Nears_ID,
          NAME,
          PARISH,
        } = feature.properties;
        tempCommunitesList.push([
          NAME,
          PARISH,
          feature.properties["B" + searchby],
        ]);
        console.log(TotSocialV);
        if (TotSocialV !== 0) {
          setTotalSocialV(TotSocialV);
        }

        if (Fishing !== 0) {
          setFishingStatus(Fishing);
        }
        if (Tourism !== 0) {
          setTourismStatus(Tourism);
        }
        if (MarNav !== 0) {
          setMarineNavigationStatus(MarNav);
        }
        if (CoaLei !== 0) {
          setLeisureStatus(CoaLei);
        }
        if (CritInf !== 0) {
          setCriticalInfrastructureStatus(CritInf);
        }
        if (BusInf !== 0) {
          setBusinessInfrastructureStatus(BusInf);
        }
        if (
          feature.properties["O" + searchby] === 1 ||
          feature.properties["O" + searchby] === 2
        ) {
          if (!tempOffShoreRiskList.some((item) => item[0] === Offs_ID)) {
            tempOffShoreRiskList.push([
              Offs_ID,
              feature.properties["O" + searchby],
            ]);
          }
        }
        if (
          feature.properties["N" + searchby] === 1 ||
          feature.properties["N" + searchby] === 2
        ) {
          if (!tempNearShoreRiskList.some((item) => item[0] === Nears_ID)) {
            tempNearShoreRiskList.push([
              Nears_ID,
              feature.properties["N" + searchby],
            ]);
          }
        }
      });

      if (tempOffShoreRiskList.length === 0) {
        setOffShoreRiskList([0]);
      } else {
        setOffShoreRiskList(tempOffShoreRiskList);
      }

      if (tempNearShoreRiskList.length === 0) {
        setNearShoreRiskList([0]);
      } else {
        setNearShoreRiskList(tempNearShoreRiskList);
      }

      // console.log(offShoreRiskList);
      const sortedList = tempCommunitesList.sort((a, b) => b[2] - a[2]);
      // Remove duplicates by those with the least in column 3
      const uniqueList = Array.from(
        sortedList
          .reduce((map, item) => {
            const key = `${String(item[0])}_${String(item[1])}`;
            if (!map.has(key) || map.get(key)[2] > item[2]) {
              map.set(key, item);
            }
            return map;
          }, new Map())
          .values()
      );
      if (
        communitiesList.length === 0 ||
        (communitiesList.length === 1 && communitiesList[0] === null)
      ) {
        setCommunitiesList(uniqueList.slice(0, 10));
      }
    }
  }

  function capitalizeFirstLetterEachWord(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const boxStyle = {
    border: "1px solid #BFBFBF",
    borderRadius: 1,
    color: "#5B5B5B",
    p: 0.5,
    alignItems: "center",
  };

  const miniGraphic = {
    border: "1px solid #BFBFBF",
    borderRadius: 1,
    color: "#5B5B5B",
    px: 1,
    py: 0.5,
    width: "100px",
  };
  return (
    <div>
      <Banner title="National Bulletin" backgroundColor="#DDC12E" />
      <div className="">
        <Box sx={{ padding: "52px 52px" }}>
          <div className="pb-4">
            <p className="w-full font-bold text-center">
              National Sargassum Early Advisory System for Jamaica
            </p>
            <p className="text-[#3F76C9] font-bold text-center pb-4 w-full ">
              {mapDate}
            </p>
          </div>
          <div className="w-[100%] overflow-x-auto">
            <Box className="w-[1200px] text-center justify-center items-center mx-auto">
              <Grid container spacing={3} columns={3}>
                <Grid sx={{}} item xs={1}>
                  <div className="">
                    <img className="" src={offShoreIcon} alt="" />
                    <div className="flex flex-col mt-[-130px]">
                      <p className="text-left text-[#E8BA58] font-bold">
                        Offshore Risk
                      </p>
                      <p className="max-w-[230px] text-left">
                        Floating mats of Sargassum expected offshore (30-75 km
                        from coastline)
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    {offShoreRiskList ? (
                      offShoreRiskList !== null &&
                      Math.max(...offShoreRiskList.map((pair) => pair[1])) ===
                        2 ? (<></>
                        // <Box
                        //   className=""
                        //   sx={{
                        //     border: "1px solid #BFBFBF",
                        //     borderRadius: 1,
                        //     color: "#5B5B5B",
                        //     p: 0.5,
                        //     alignItems: "center",
                        //     textAlign: "center",
                        //     flexShrink: 0,
                        //     width: "min-content",
                        //   }}
                        // >
                        //   <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                        //     <img src={risk_major_icon} alt="Risk Icon" />
                        //     <p>MAJOR</p>
                        //   </div>
                        // </Box>
                      ) : Math.max(
                          ...offShoreRiskList.map((pair) => pair[1])
                        ) === 1 ? (
                        <Box
                          className=""
                          sx={{
                            border: "1px solid #BFBFBF",
                            borderRadius: 1,
                            color: "#5B5B5B",
                            p: 0.5,
                            alignItems: "center",
                            textAlign: "center",
                            flexShrink: 0,
                            width: "min-content",
                          }}
                        >
                          <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                            <img src={risk_minor_icon} alt="Risk Icon" />
                            <p>MINOR</p>
                          </div>
                        </Box>
                      ) : (
                        <Box
                          className=""
                          sx={{
                            border: "1px solid #BFBFBF",
                            borderRadius: 1,
                            color: "#5B5B5B",
                            p: 0.5,
                            alignItems: "center",
                            textAlign: "center",
                            flexShrink: 0,
                            width: "min-content",
                          }}
                        >
                          <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                            <img src={risk_none_icon} alt="Risk Icon" />
                            <p>NONE</p>
                          </div>
                        </Box>
                      )
                    ) : null}
                    <div className="pt-2"></div>
                    {offShoreRiskList ? (
                      <Box
                        className="flex flex-col text-center items-center justify-center "
                        sx={{
                          border: "1px solid #BFBFBF",
                          borderRadius: 1,
                          color: "#5B5B5B",
                          p: 0.5,
                          alignItems: "left",
                          textAlign: "left",
                          flexShrink: 0,
                          width: "min-content",
                        }}
                      >
                        <img className="w-[60px]" src={zone_icon} alt=""></img>
                        <div className="flex gap-1">
                          {offShoreRiskList.map((area) => (
                            <Box key={area} sx={boxStyle}>
                              <p>{area[0]}</p>
                            </Box>
                          ))}
                        </div>
                      </Box>
                    ) : null}
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <div className="flex flex-col text-center align-center items-center justify-center mx-auto ">
                    <img src={beachingRiskIcon} />
                    <p className="text-[#D86A9A] font-bold">
                      Beaching Risk for Communities
                    </p>
                    <p className="text-[#4B4B4B] font-bold pt-2">
                      Top 10 Communities most affected by sargassum beaching
                      risk:
                    </p>
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <img className="" src={specificCoastalActivitiesIcon} />
                  <p className="mx-auto mr-[0px] mt-[-120px] text-right w-[200px] text-[#8538A4] font-bold">
                    Specific Coastal Activities Affected
                  </p>
                  <div className="w-[100%] flex justify-end">
                    <div className="flex w-[70%] flex-wrap mx-auto justify-end mr-[0px] gap-2">
                      {sectors_data &&
                        sectors_data.map((sector) =>
                          sector.status !== 0 ? (
                            <a href={"/sectors-under-risk/#" + sector.id}>
                              {/* <p>{sector.id}</p> */}
                              <Box
                                key={sector}
                                className="flex flex-col items-center h-full"
                                sx={{
                                  width: "min-content",
                                  border: "1px solid #BFBFBF",
                                  borderRadius: 1,
                                  color: "#5B5B5B",
                                  px: 0.5,
                                  py: 0.5,
                                }}
                              >
                                <img
                                  className="h-[30px]"
                                  src={sector.icon}
                                  alt={sector.sectionTitle + " Icon"}
                                />
                                <p className="font-bold pt-1 text-[12px]">
                                  {sector.sectionTitle}
                                </p>
                              </Box>
                            </a>
                          ) : null
                        )}
                    </div>
                  </div>
                </Grid>

                <Grid sx={{}} item xs={1}>
                  <div className="">
                    <img
                      className="mt-[-70px]"
                      src={nearshoreRiskIcon}
                      alt=""
                    />
                    <div className="flex flex-col mt-[-20px]">
                      <p className="text-left text-[#DF9076] font-bold">
                        Nearshore Risk
                      </p>
                      <p className="max-w-[230px] text-left">
                        Floating mats of Sargassum expected nearshore (30 km
                        from coastline)
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    {nearShoreRiskList ? (
                      nearShoreRiskList !== null &&
                      Math.max(...nearShoreRiskList.map((pair) => pair[1])) ===
                        2 ? (
                        <></>
                        // <Box
                        //   className=""
                        //   sx={{
                        //     border: "1px solid #BFBFBF",
                        //     borderRadius: 1,
                        //     color: "#5B5B5B",
                        //     p: 0.5,
                        //     alignItems: "center",
                        //     textAlign: "center",
                        //     flexShrink: 0,
                        //     width: "min-content",
                        //   }}
                        // >
                        //   <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                        //     <div className="flex text-center flex-col items-center justify-center">
                        //       <div
                        //         className="bulletin-icon flex flex-col items-center justify-center mx-auto"
                        //         id="nearshore-risk-bulletin-icon"
                        //       >
                        //         <div className="flex flex-col justify-center items-center">
                        //           <img
                        //             src={risk_major_icon}
                        //             alt={"Nearshore Risk Major Icon"}
                        //           ></img>
                        //           <p id="nearshore-risk-bulletin-text">MAJOR</p>
                        //         </div>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </Box>
                      ) : Math.max(
                          ...nearShoreRiskList.map((pair) => pair[1])
                        ) === 1 ? (
                        <Box
                          className=""
                          sx={{
                            border: "1px solid #BFBFBF",
                            borderRadius: 1,
                            color: "#5B5B5B",
                            p: 0.5,
                            alignItems: "center",
                            textAlign: "center",
                            flexShrink: 0,
                            width: "min-content",
                          }}
                        >
                          <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                            <div className="flex text-center flex-col items-center justify-center">
                              <div
                                className="bulletin-icon flex flex-col items-center justify-center mx-auto"
                                id="nearshore-risk-bulletin-icon"
                              >
                                <div className="flex flex-col justify-center items-center">
                                  <img
                                    src={risk_minor_icon}
                                    alt={"Risk Minor Icon"}
                                  ></img>
                                  <p id="nearshore-risk-bulletin-text">MINOR</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Box>
                      ) : Math.max(
                          ...nearShoreRiskList.map((pair) => pair[1])
                        ) === 0 ? (
                        <Box
                          className=""
                          sx={{
                            border: "1px solid #BFBFBF",
                            borderRadius: 1,
                            color: "#5B5B5B",
                            p: 0.5,
                            alignItems: "center",
                            textAlign: "center",
                            flexShrink: 0,
                            width: "min-content",
                          }}
                        >
                          <div className="w-[80px] flex flex-col justify-center items-center text-center mx-auto">
                            <div className="flex text-center flex-col items-center justify-center">
                              <div
                                className="bulletin-icon flex flex-col items-center justify-center mx-auto"
                                id="nearshore-risk-bulletin-icon"
                              >
                                <div className="flex flex-col justify-center items-center">
                                  <img
                                    src={risk_minor_icon}
                                    alt={"Nearshore Risk Icon"}
                                  ></img>
                                  <p id="nearshore-risk-bulletin-text">NONE</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Box>
                      ) : (
                        ""
                      )
                    ) : null}
                    <div className="pt-2"></div>

                    {nearShoreRiskList && nearShoreRiskList.length !== 0 ? (
                      <Box
                        className="flex flex-col text-center items-center justify-center min-w-[60px] "
                        sx={{
                          border: "1px solid #BFBFBF",
                          borderRadius: 1,
                          color: "#5B5B5B",
                          p: 0.5,
                          alignItems: "left",
                          textAlign: "left",
                          flexShrink: 0,
                          display: "inline-block",
                          width: "min-content",
                        }}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <img
                            className="w-[60px]"
                            src={zone_icon}
                            alt={"Zone Map of Jamaica Icon"}
                          />
                          <div className="flex gap-1">
                            {nearShoreRiskList.map((area) => (
                              <Box key={area} sx={boxStyle}>
                                <p>{area[0]}</p>
                              </Box>
                            ))}
                          </div>
                        </div>
                      </Box>
                    ) : null}
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  {/* <p>Communities list here</p> */}
                  <div className="beaching-risk-first-section">
                    {/* {console.log(communitiesList[0])} */}
                    {communitiesList &&
                      communitiesList.map((community, index) => (
                        <div key={index}>
                          {capitalizeFirstLetterEachWord(community[0])},{" "}
                          {capitalizeFirstLetterEachWord(community[1])}
                        </div>
                      ))}
                  </div>
                </Grid>
                <Grid sx={{}} item xs={1}>
                  <img
                    className="mt-[-70px]"
                    src={transformationalOpportunitiesIcon}
                    alt={"Transformational Opportunities Icon"}
                  />
                  <p className="mx-auto mr-[0px] mt-[-10px] text-right w-[200px] text-[#C863D4] font-bold">
                    Transformational Opportunities
                  </p>

                  <div className="text-center mr-[0px] mx-auto w-[200px] flex flex-col gap-2">
                    <div className="flex gap-2">
                      {totalSocialV !== 0 ? (
                        <Box
                          className="font-bold flex flex-col text-center items-center align-center mx-auto justify-center"
                          sx={miniGraphic}
                        >
                          <img
                            src={mini_graphic_alternative_use_of_sargassum}
                            alt={"Alternative Use of Sargassum Icon"}
                          />
                          <p className="text-[12px]">
                            Alternative Use of Sargassum
                          </p>
                        </Box>
                      ) : (
                        ""
                      )}
                      {console.log(totalSocialV)}
                      {totalSocialV !== 0 ? (
                        <Box
                          className="font-bold flex flex-col text-center items-center align-center mx-auto justify-center"
                          sx={miniGraphic}
                        >
                          <img
                            src={mini_graphic_offshore_presence}
                            alt={"Offshore Presence Icon"}
                          />
                          <p className="text-[12px]">Offshore Presence</p>
                        </Box>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Box>
      </div>
    </div>
  );
};
