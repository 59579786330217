import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { CustomButton } from "../pages/components/buttons.js";

export var SucessfulLogOut = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box className="text-center h-[700px]" sx={{ padding: "56px 56px" }}>
        <div className="">
          <h1>You have been sucessfully logged out.</h1>
        </div>
        <div className="pt-3">
          <a href="/index">
            <CustomButton label="← Go Back Home" />
          </a>
        </div> 
      </Box>
    </div>
  );
}; 
