import React, { useEffect } from "react";
import errorAnimatino from "../animations/404NotFoundAnimation.json";
import Lottie from "react-lottie";
import { Box } from "@mui/material";
import { CustomButton } from "./components/buttons";

export var NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const animation = {
    loop: true,
    autoplay: true,
    animationData: errorAnimatino,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Box
        className="text-center flex flex-col justify-center items-center"
        sx={{ padding: "56px 32px" }}
      >
        <Box className="max-w-[400px]">
          <Lottie options={animation} height="100%" width="100%" />
        </Box>
        <p className="text-[24px] font-bold text-[#0F5B32]">Page not found!</p>
        <p className="max-w-[450px] text-[grey] pb-8">
          Oops! Looks like the page you're looking for doesn't exist. It might
          be an error, but head back to home or try one of the links above.
        </p>

        <CustomButton
          style={{ type: "dark" }}
          link="/index"
          type="link"
          label="Return to Home"
        />
      </Box>
    </div>
  );
};
