import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Landingpage } from "./pages/LandingPage";
import LogIn from "./pages/LogIn.js";
import Signup from "./pages/SignUp";
import { ViewUsers } from "./pages/ViewUsers";
import { AddUser } from "./pages/AddUser";
import { NotFoundPage } from "./pages/404Page";
import { EditUser } from "./pages/EditUser";
import { About } from "./pages/About";
import { CommunityBulletin } from "./pages/CommunityBulletin.js";
import { NationalBulletin } from "./pages/NationalBulletin.js";
import { ChangePassword } from "./pages/ChangePassword";
import TokenConfirmation from "./pages/TokenConfirmationPage";
import { SucessfulLogOut } from "./pages/SuccessfulLogOut";
import { Upload } from "./pages/Upload";
import { RiskPresence } from "./pages/RiskPresenceMap";
import { ViewSubscribers } from "./pages/ViewSubscribers";
import { SectorsUnderRisk } from "./pages/SectorsUnderRisk";
import { CommunityListing } from "./pages/CommunityListing";
import { SucessfulUnsubscribe } from "./pages/SuccessfullUnsubscribe";
import React from "react";
import "./css/styles.css";
import MGILogoFooter from "./imgs/mgiLogoFooter.png";
import southhampton_logo from "./imgs/southhampton_logo.png";
import LogoMobile from "./imgs/logo_mobile.png";
import LogoDesktop from "./imgs/logo_desktop.png";
import { useState, useEffect } from "react";
import { loadPageUserType } from "./functions";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CustomButton } from "./pages/components/buttons.js";
import { mobileUserDropdown } from "./constantData.js";
import { pages } from "./constantData.js";
import { quickLinks } from "./constantData.js";
import { connectLinks } from "./constantData.js";
import { UploadRomario } from "./pages/UploadRomario.js";
import { RiskPresenceRomario } from "./pages/RiskPresenceMapRomario.js";

export default function App() {
  const [showSection, setShowSection] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);

  useEffect(() => {
    setShowSection(loadPageUserType());
  }, []);
  // var showSection = setShowSection;
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };
  function logUserOut() {
    localStorage.setItem("user_type", "none");
    localStorage.setItem("userEmail", "");
    window.location.href = "/sucessfullogout";
  }

  // console.log("he");
  // console.log(showSection);
  return (
    <BrowserRouter>
      <AppBar
        sx={{
          background: "white",
          boxShadow: "none",
          borderBottom: "1px solid lightGrey",
          padding: "16px 0px",
        }}
        position="static"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{}}>
            {/* <MenuIcon/> */}
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 600,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                zIndex: 99,
              }}
            >
              <img src={LogoDesktop} alt="JSEAS Logo" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                {/* <MenuIcon /> */}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <a
                    className="no-underline"
                    href={page.route}
                    rel="noopener noreferrer"
                  >
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Typography sx={{}} textAlign="center">
                        {page.name}
                      </Typography>
                    </MenuItem>
                  </a>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                color: "black",
                marginLeft: "auto",
              }}
            >
              {showSection !== "admin" && showSection !== "general" ? (
                <div className="flex justify-center items-center">
                  <a href="sign-up">
                    <Button
                      sx={{
                        color: "#6D6D6D",
                        fontWeight: 500,
                        fontSize: 16,
                        display: "block",
                        textTransform: "none",
                        padding: "0 24px",
                      }}
                      variant="text"
                    >
                      Subscribe to Our Newsletter
                    </Button>
                  </a>

                  <Link to="/log-in">
                    <CustomButton
                      style={{ type: "light" }}
                      link="/log-in"
                      type="link"
                      label="Admin Log In"
                    />
                  </Link>
                </div>
              ) : (
                <div className="flex">
                  <Button
                    sx={{
                      color: "#6D6D6D",
                      fontWeight: 500,
                      fontSize: 16,
                      display: "block",
                      textTransform: "none",
                      padding: "0 24px",
                    }}
                    variant="text"
                  >
                    <a href="/sucessfullogout" onClick={logUserOut}>
                      Sign Out
                    </a>
                  </Button>
                  <div>
                    <Button
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        backgroundColor: "#288151",
                        "&:hover": {
                          backgroundColor: "#2F9A60",
                        },
                        "&:active": {
                          backgroundColor: "#2F9A60",
                        },
                      }}
                      variant="contained"
                      id="demo-positioned-button"
                      aria-controls={
                        Boolean(anchorElAdmin)
                          ? "demo-positioned-menu"
                          : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        Boolean(anchorElAdmin) ? "true" : undefined
                      }
                      onClick={handleOpenAdminMenu}
                    >
                      User Actions
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElAdmin}
                      open={Boolean(anchorElAdmin)}
                      onClose={handleCloseAdminMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className="flex flex-col p-2">
                        {mobileUserDropdown.map((pages) =>
                          pages.user.includes(showSection) ? (
                            pages.name === "Sign Out" ? (
                              <div onClick={logUserOut}>
                                <p>{pages.name}</p>
                              </div>
                            ) : (
                              <Link className="pb-2" to={pages.route}>
                                {pages.name}
                              </Link>
                            )
                          ) : null
                        )}
                        {mobileUserDropdown.map((pages) =>
                          showSection !== "admin" &&
                          showSection !== "general" ? (
                            pages.user.includes("all") ? (
                              <Link className="pb-2" to={pages.route}>
                                {pages.name}
                              </Link>
                            ) : null
                          ) : null
                        )}
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 500,
                letterSpacing: ".3rem",
                color: "black",
                textDecoration: "none",
              }}
            >
              <img src={LogoMobile} alt="JSEAS Logo" />
            </Typography>
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div className="flex flex-col p-2">
                  {mobileUserDropdown.map((pages) =>
                    pages.user.includes(showSection) ? (
                      pages.name === "Sign Out" ? (
                        <div onClick={logUserOut}>
                          <p>{pages.name}</p>
                        </div>
                      ) : (
                        <Link className="pb-2" to={pages.route}>
                          {pages.name}
                        </Link>
                      )
                    ) : null
                  )}
                  {/* {console.log(showSection)} */}
                  {mobileUserDropdown.map((pages) =>
                    showSection !== "admin" && showSection !== "general" ? (
                      pages.user.includes("all") ? (
                        <Link className="pb-2" to={pages.route}>
                          {pages.name}
                        </Link>
                      ) : null
                    ) : null
                  )}
                </div>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <AppBar
        sx={{
          background: "white",
          boxShadow: "none",
          borderBottom: "1px solid lightGrey",
        }}
        position="static"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{}}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleOpenNavMenu}
                color="black"
              >
                {/* <MenuIcon /> */}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <Link
                    to={page.route}
                    className="no-underline"
                    href={page.route}
                    rel="noopener noreferrer"
                  >
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Typography sx={{}} textAlign="center">
                        {page.name}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <nav className="flex">
                {pages.map((page) => (
                  <Link
                    className="no-underline"
                    href={page.route}
                    rel="noopener noreferrer"
                    to={page.route}
                  >
                    <Button
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: "#6D6D6D",
                        fontWeight: 500,
                        fontSize: 16,
                        display: "block",
                        textTransform: "none",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                ))}
              </nav>
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ px: 1 }}>
                <AccountCircleIcon />
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
              </IconButton>
              <IconButton onClick={handleOpenNavMenu} sx={{ p: 0 }}>
                <MenuIcon />
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <Link
                    className="no-underline"
                    href={page.route}
                    rel="noopener noreferrer"
                    to={page.route}
                  >
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <p>{page.name}</p>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Routes>
        <Route path="" element={<Landingpage />} />
        <Route path="/index" element={<Landingpage />} />
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/unsubscribed/:email" element={<SucessfulUnsubscribe />} />
        {/* <Route path="/subscribe-confirmation" element={<SubscribeConfirm/>}/> */}
        <Route path="/about" element={<About />} />
        <Route path="/community-bulletin" element={<CommunityBulletin />} />
        <Route path="/national-bulletin" element={<NationalBulletin />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/upload-romario" element={<UploadRomario />} />
        <Route path="/risk-presence-map-romario" element={<RiskPresenceRomario />} />

        <Route path="/sucessfullogout" element={<SucessfulLogOut />} />
        <Route path="/add-user" element={<AddUser />} />
        
        <Route path="/view-users" element={<ViewUsers />} />
        <Route path="/edit-user/:user_id" element={<EditUser />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/risk-presence-map" element={<RiskPresence />} />
        <Route path="/view-subscribers" element={<ViewSubscribers />} />
        <Route
          path="/token-confirmation/:email_token"
          element={<TokenConfirmation />}
        />
        <Route path="/sectors-under-risk" element={<SectorsUnderRisk />} />
        <Route path="/communities-listing" element={<CommunityListing />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {showSection === "none" ? <Footer /> : null}
      {showSection === "general" ? <FooterGeneral /> : null}
      {showSection === "admin" ? <FooterAdmin /> : null}
    </BrowserRouter>
  );
}

export let Footer = (showSection) => {
  var companies = [
    {
      name: "MonaGeo Informatics Institute",
      img: MGILogoFooter,
      alt: "MonaGeo Informatics Logo",
      link: "http://main.monagis.com/",
    },
    {
      name: "South Hampton",
      img: southhampton_logo,
      alt: "South Hampton Logo",
      link: "https://www.southampton.ac.uk",
    },
  ];

  return (
    <Box
      className="flex flex-col lg:flex-row text-center justify-center items-center lg:items-start mx-auto space-x-8 "
      sx={{ backgroundColor: "#9DC992", padding: "56px 56px", gap: 2 }}
    >
      <Box>
        <Box className="text-center items-center max-w-[300px] justify-center mx-auto">
          {companies.map((company) => (
            <a href={company.link} target="_blank" rel="noreferrer">
              <img src={company.img} alt={company.alt} />
            </a>
          ))}
        </Box>
      </Box>
      <Box className="text-[#0F5B32] font-[500] lg:text-left max-w-[200px] justify-center mx-auto">
        <p>The University of the West Indies Mona, Kingston 7, Jamaica, W.I.</p>
        <p>Tel: (876) 927-1660-9</p>
        <p>Tel: (876) 927-2765</p>
      </Box>
      <Box className="text-[#0F5B32] font-[500] flex flex-col max-w-[200px] text-center  mx-auto lg:text-left">
        <p className="font-bold">Quick Links</p>
        {quickLinks.map((links) => (
          <Link to={links.route}> {links.name}</Link>
        ))}
      </Box>
      <Box className="text-[#0F5B32] font-[500] flex flex-col max-w-[200px] text-center  mx-auto  lg:text-left">
        <p className="font-bold">Connect</p>
        <p>Media Center</p>
        <p>Contact Us</p>
        <div className="flex text-center items-center justify-center pt-2">
          {connectLinks.map((links) => (
            <a href={links.link} target="_blank" rel="noopener noreferrer">
              {" "}
              {links.icon}
            </a>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export var SimpleNavBar = function () {
  return (
    <div className="flex justify-center py-4 font-bold text-[#30AF6A] text-3xl">
      <h3>JSEAS Portal</h3>
    </div>
  );
};

export var SimpleFooter = function () {
  return <div className="simple-footer"></div>;
};

export let FooterAdmin = () => {
  return <Footer showSection="admin" />;
};

export let FooterGeneral = () => {
  return <Footer showSection="general" />;
};
