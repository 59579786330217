import React, { useEffect } from "react";
import about_us_image from "../imgs/about_us_image.png";
import { project_members } from "../constantData";
import { SubContentBox } from "./components/content_box";
import { Box } from "@mui/material";
import { Banner } from "./components/banner";

export var About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Banner title={"About the Project"} backgroundColor="#30AF6A" />
      <Box
        className="flex flex-col-reverse lg:flex-row"
        sx={{
          gap: { lg: 8 },
        }}
      >
        <Box
          sx={{ padding: "32px 24px" }}
          className="lg:w-3/4 text-center items-center mx-auto my-auto"
        >
          <p className="w-full lg:max-w-[700px]">Main Purpose</p>
          <p className="text-[grey] pb-4 w-full lg:max-w-[700px]">
            The goal of the Early Advisory System for Jamaica is to analyse data
            from satellite imagery, field work, secondary sources and drones to
            produce a dynamic system that incorporates wind and wave conditions
            in the predictions of Sargassum beaching alerts across Jamaica.
          </p>
          <p className="w-full lg:max-w-[700px]">Main Outcomes</p>

          <p className="text-[grey] pb-4 w-full lg:max-w-[700px]">
            To provide near real time alerts for the location of nearshore,
            offshore and beached Sargassum for different sectors of the
            coastline.
          </p>
          <p className="text-[grey] pb-4 w-full lg:max-w-[700px]">
            To provide alerts specific to various communities and sectors based
            on their vulnerability and interests in Sargassum.
          </p>
          <p className="text-[grey] pb-4 w-full lg:max-w-[700px]">
            To improve the resiliency and the potential for utilizing the
            seaweed as a means of transformational adaptation.
          </p>
        </Box>
        <Box
          className="justify-center items-center mx-auto my-auto"
          style={{
            backgroundImage: `url(${about_us_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "60vh", // This makes the image cover the entire viewport height
          }}
        ></Box>
      </Box>

      <SubContentBox
        align="left"
        subHeader={"The brains behind the idea"}
        header={"Meet the team"}
        subText="Our team of developers are some of the great minds that helped build this website."
        backgroundColor="#DBF6D4"
        otherContent={
          <div className="flex flex-wrap gap-8 pt-8 justify-center  text-center">
            {project_members.map((member) => (
              <div key={member.id} className="flex  flex-col items-center">
                <div className="w-200 h-200 overflow-hidden">
                  <img
                    src={member.photo}
                    alt={member.name}
                    className="object-cover w-full h-full rounded-sm"
                  />
                </div>
                <div className="flex flex-col ml-2 pt-2">
                  <div className="font-bold text-[#30AF6A]">{member.name}</div>
                  <div className="font-bold text-[#434343] w-[200px]">
                    {member.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};
