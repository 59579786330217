import React from "react";
import { useEffect, useState } from "react";
import { UPDATE_USER_PASSWORD_URL } from "../apiEndPoints";
import { loadPageUserType } from "../functions";
import { Banner } from "./components/banner";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import { NotFoundPage } from "./404Page";
import { CustomButton } from "./components/buttons";
import { Link } from "react-router-dom";

export var ChangePassword = () => {
  const [showSection, setShowSection] = useState(false);
  const [inputValue, setInputValue] = useState("");
  console.log(inputValue);
  useEffect(() => {
    setShowSection(loadPageUserType());
    window.scrollTo(0, 0);
  }, []);

  async function changeUserPassword() {
    var old_password = await document.getElementById("password_input");
    var new_password = await document.getElementById("new_password_input");
    var new_password_confirmation = await document.getElementById(
      "confirm_new_password_input"
    );

    console.log(old_password.value);
    console.log(new_password.value);
    console.log(new_password_confirmation.value);
    if (
      (old_password.value === "" ||
        old_password.value === null ||
        old_password.value === " " ||
        new_password.value === "" ||
        new_password.value === null ||
        new_password.value === " " ||
        new_password_confirmation.value === "" ||
        new_password_confirmation.value === null ||
        new_password_confirmation.value === " ") === false
    ) {
      if (new_password.value === new_password_confirmation.value) {
        // alert("passwords match")

        fetch(UPDATE_USER_PASSWORD_URL(localStorage.getItem("userEmail")), {
          // Adding method type
          method: "PUT",

          // Adding body or contents to send
          body: JSON.stringify({
            old_password: old_password.value,
            new_password: new_password.value,
          }),

          // Adding headers to the request
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          // Converting to JSON
          .then((response) => {
            response.json();

            if (response.ok === false) {
              alert("There was an error <error message>");
            } else {
              // displayAlertNotification("User password has been changed.");
              //alert("User password has been changed.")
              old_password.value = "";
              new_password.value = "";
              new_password_confirmation.value = "";
              // email.value = "";
            }
          })
          // Displaying results to console
          .then((data) => {
            // alert("User has been added with password: " + random_password)
            //alert("Data" + data.json());
          });
      } else {
        // displayAlertNotification(
        //   "Please ensure New Password and Confirm New Password match."
        // );
      }
    } else {
      // displayAlertNotification(
      //   "Please ensure all fields are entered correctly."
      // );
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      changeUserPassword();
    }
  };

  return (
    <div className="">
      {showSection === "general" || showSection === "admin" ? (
        <div>
          <Banner title={"Change Password"} backgroundColor="#30AF6A" />

          <div id="alert-notification" className="alert-notification-invisible">
            {/* <p>Test</p> */}
          </div>
          <Box
            className=""
            sx={{
              padding: "32px 56px",
              height: "100%",
            }}
          >
            <p className="font-bold text-[24px]">Change Your Password</p>
            <p className="text-[grey] pb-4 max-w-[700px]">
              Enter the details below necessary to change your password.
            </p>
            <form className="flex flex-col gap-4 md:w-[450px]">
              <TextField
                id="password_input"
                type="password"
                placeholder="******"
                onChange={(event) => setInputValue(event.target.value)}
                onKeyDown={handleKeyDown}
                required
                label="Previous Password"
                name="name_to"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="new_password_input"
                type="password"
                placeholder="******"
                onChange={(event) => setInputValue(event.target.value)}
                onKeyDown={handleKeyDown}
                required
                label="New Password"
                name="name_to"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="confirm_new_password_input"
                type="password"
                placeholder="******"
                onChange={(event) => setInputValue(event.target.value)}
                onKeyDown={handleKeyDown}
                required
                label="Confirm New Password"
                name="name_to"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <div className="pt-4 flex">
              <div onClick={changeUserPassword}>
                <CustomButton label="Edit User" style={{ type: "dark" }} />
              </div>
              <Link to="/index"> 
                <CustomButton label="Cancel" type="cancel" />
              </Link>
            </div>
            <Box sx={{ backgroundColor: "red", height: "100%" }}></Box>
          </Box>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};
