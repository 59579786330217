import React from "react";
import { useState, useEffect } from "react";
import { GET_SUBSCRIBERS_URL, REMOVE_SUBSCRIBER_URL } from "../apiEndPoints";
import { loadPageUserType } from "../functions";
import { Banner } from "./components/banner";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { NotFoundPage } from "./404Page";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
 
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export var ViewSubscribers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [posts, setPosts] = useState([]);
  const [showSection, setShowSection] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSeverity, setNotificationSeverity] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(false); // New state variable

  const reloadSection = () => {
    // Increment the key to trigger a re-render
    setReloadKey((prevKey) => prevKey + 1);
    setReloadFlag((prevFlag) => !prevFlag);
  };

  const handleClick = (message, severity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fetchPosts = async () => {
    setShowSection(loadPageUserType());
    fetch(GET_SUBSCRIBERS_URL, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, [reloadFlag, reloadKey]);
  
  


  function removeSubscriber(subscriber) {
    // alert(user_email);
    console.log(subscriber.subscriber_email);
    if (
      window.confirm(
        "Are you sure you would like to remove this subscriber?"
      ) === true
    ) {
      // alert("Subscriber been removed from the system.");
      // displayAlertNotification("Subscriber has been removed from the system");
      console.log("User has been removed from the system.");

      fetch(REMOVE_SUBSCRIBER_URL(subscriber.subscriber_email), {
        // Adding method type
        method: "DELETE",

        // Adding body or contents to send
        body: JSON.stringify({
          // "email": email.value
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        // Converting to JSON
        .then((response) => {
          response.json();

          if (response.ok === false) {
            alert("There was an error <error message>");
          } else {
            // alert("Subscriber has been removed from the system.");
            // displayAlertNotification(
            //   "Subscriber has been removed from the system."
            // );

            handleClick("User has been removed from the system.", "success");
            reloadSection();
            // window.location.reload();
            // alert("User with email " + email.value + " has been removed")
            // email.value = "";
          }
        })
        // Displaying results to console
        .then((data) => {
          // alert("User has been added with password: " + random_password)
          //alert("Data" + data.json());
        });
    } else {
      console.log("Subscriber has not been removed from the system.");
    }
  }

  return (
    <div>
      {showSection === "admin" | showSection === "general" ? (
        <Box>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={notificationSeverity}
                sx={{
                  zIndex: 9999,
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {notificationMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Banner title="Subscribers" backgroundColor="#30AF6A" />
          <Box sx={{ padding: "32px 56px" }}>
            <p className="font-bold text-[24px]">View Subscribers</p>
            <p className="text-[grey] pb-4 max-w-[700px]">
              See below a list of all subscribers in the system. All users will
              receive an email notification when there is a change in the map.
            </p>
            <TableContainer
            key={reloadKey}
              sx={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              component={Paper}
            >
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Email
                    </TableCell>
                    {showSection === "admin" ? (   

                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Options
                    </TableCell> 
                    
                    )  : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {posts.map((row) => (
                    <TableRow
                      // key={row.first_name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.first_name + " "}
                        {row.last_name}
                      </TableCell>
                      <TableCell align="left">{row.subscriber_email}</TableCell>
                      {showSection === "admin" ? (  
                      <TableCell align="center">
                        <ul className="flex justify-center mx-auto">
                          <li key="{post.user_id}">
                            <div
                              onClick={() =>
                                removeSubscriber({
                                  subscriber_email: row.subscriber_email,
                                })
                              }
                            >
                              <DeleteIcon />
                            </div>
                          </li>
                        </ul>
                      </TableCell>) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};
