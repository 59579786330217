import { Button } from "@mui/material";

export var CustomButton = ({ label, style, link, type, action, onClick, backgroundColor }) => {
  var buttonStyle = {
    darkButton: {
      textTransform: "none",
      fontWeight: "bold",
      backgroundColor: "#288151",
      "&:hover": {
        backgroundColor: "#2F9A60",
      },
      "&:active": {
        backgroundColor: "#2F9A60",
      },
    },

    lightButton: {
      textTransform: "none",
      fontWeight: "bold",
      backgroundColor: "#30AF6A",
      "&:hover": {
        backgroundColor: "#3DC47A",
      },
      "&:active": {
        backgroundColor: "#3DC47A",
      },
    },
  };

  return (
    <div>
      {type === "link" ? (
        <Button
          sx={
            style === "dark" ? buttonStyle.darkButton : buttonStyle.lightButton
          }
          href={link}
          variant="contained"
        >
          {label}
        </Button>
      ) : type === "action" ? (
        <Button
          sx={
            style === "dark" ? buttonStyle.darkButton : buttonStyle.lightButton
          }
          variant="contained"
        >
          {label}
        </Button>
      ) : type === "onClick" ? (
        <Button
          sx={
            style === "dark" ? buttonStyle.darkButton : buttonStyle.lightButton
          }
          variant="contained"
        >
          {label}
        </Button>
      ) : type === "cancel" ? (
        <Button sx={{ color: "red", fontWeight: "bold", textTransform: "none" }} variant="text">
          {label}
        </Button>
      ) : (
        <Button
          sx={
            style === "dark" ? buttonStyle.darkButton : buttonStyle.lightButton
          }
          variant="contained"
        >
          {label}
        </Button>
      )}
    </div>
  );
};
