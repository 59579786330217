// const API_ROOT = '159.223.110.41:8000';
const API_ROOT = 'https://jseas.monagis.com/api';

export const GET_COMMUNITIES_URL = `${API_ROOT}/getCommunities`;
export const VIEW_USERS_URL = `${API_ROOT}/getUsers`;
export const GET_SUBSCRIBERS_URL = `${API_ROOT}/getSubscribers`;
export const REMOVE_SUBSCRIBER_URL = (email) => `${API_ROOT}/removeSubscriber/${email}`
export const GET_RISK_PRESENCE_MAP_DATA_URL = `${API_ROOT}/getRiskPresenceMapData`;
export const GET_RISK_PRESENCE_MAP_OFFSHORE_NEARSHORE_URL = `${API_ROOT}/getRiskPresenceMapOffshoreNearshore`;
export const GET_RISK_PRESENCE_DATE_URL = `${API_ROOT}/getRiskPresenceDate`;
export const GET_TOP_AFFECT_COMMUNITIES_BEACHING_RISK_URL = `${API_ROOT}/getTopAffectCommunitiesBeachingRisk`;
export const GET_COMMUNITY_PARISH_URL = (community_name) => `${API_ROOT}/getCommunityParish/${community_name}`;
export const GET_COMMUNITY_BULLETIN_COMMUNITY_URL = (community_name) => `${API_ROOT}/getCommunityBulletin_Community/${community_name}`;
export const GET_USER_BY_ID_URL = (id) => `${API_ROOT}/getUserById/${id}`;
export const GET_USER_TYPE_BY_EMAIL_URL = (email) => `${API_ROOT}/getUserTypeByEmail/${email}`;
export const ADD_USER_TOKEN_URL = `${API_ROOT}/addUserToken`;
export const UPDATE_USER_PASSWORD_URL = (email) => `${API_ROOT}/updateUserPassword/${email}`;
export const REMOVE_USER_URL = (email) => `${API_ROOT}/removeUser/${email}`;
export const UPDATE_USER_URL = (id) => `${API_ROOT}/updateUser/${id}`;
export const ADD_SUBSCRIBER_URL = `${API_ROOT}/addSubscriber`;
export const VERIFY_USER_URL = `${API_ROOT}/verifyUser`;
export const DELETE_USER_TOKEN_URL = `${API_ROOT}/deleteUserToken`;
export const CHECK_USER_TOKEN_URL = (token) => `${API_ROOT}/checkUserToken/${token}`;
export const UPDATE_ZONES_NEARSHORE_URL = (nearshore_zone) => `${API_ROOT}/updateZonesNearshore/${nearshore_zone}`;
export const UPDATE_ZONES_OFFSHORE_URL = (offshore) =>  `${API_ROOT}/updateZonesOffshore/${offshore}`;
export const CHANGE_PASSWORD_VIA_EMAIL_URL = (email) => `${API_ROOT}/changePasswordViaEmail/${email}`;
export const UPDATE_RISK_PRESENCE_MAP_JSON_FILE_URL = `${API_ROOT}/updateRiskPresenceMapJSONFile`;
export const UPDATE_COMMUNITY_BULLETIN_URL = `${API_ROOT}/updateCommunityBulletin`;
export const UPDATE_RISK_PRESENCE_DATE_URL = `${API_ROOT}/updateRiskPresenceDate`;
export const UPDATE_RISK_PRESENCE_MAP_OFFSHORE_NEARSHORE_FILE_URL = `${API_ROOT}/updateRiskPresenceMapOffshoreNearshoreFile`;
export const GET_USER_BY_FIRST_NAME_URL = (first_name) => `${API_ROOT}/getUserByFirstName/${first_name}`;
export const GET_ROLES_URL = `${API_ROOT}/getRoles`;
export const GET_PARISHES_URL = `${API_ROOT}/getParishes`;
export const GET_SECTORS_URL = `${API_ROOT}/getSectors`;
export const GET_USER_ID_BY_EMAIL_URL = (email) => `${API_ROOT}/getUserIdByEmail/${email}`;
export const GET_RISK_PRESENCE_MAP_BY_OBJECT_ID_URL = (object_id) => `${API_ROOT}/getRiskPresenceMapByObjectID/${object_id}`;
export const GET_ZONES_NEARSHORE_URL = (zone) => `${API_ROOT}/getZonesNearshore/${zone}`;
export const GET_ZONES_OFFSHORE_URL = (zone) => `${API_ROOT}/getZonesOffshore/${zone}`;
export const UPDATE_USER_EMAIL_URL = (email) => `${API_ROOT}/updateUserEmail/${email}`;
export const UPDATE_USER_STATUS_URL = `${API_ROOT}/updateUserStatus`;
export const SET_USER_LOGIN_TOKEN_URL = (id) => `${API_ROOT}/setUserLogInToken/${id}`;
export const ADD_USER_URL = `${API_ROOT}/addUser`;
export const CREATE_EMAIL_TOKEN_URL = `${API_ROOT}/createEmailToken`;
export const UPDATE_USER_LOGIN_TOKEN_URL = (id) => `${API_ROOT}/updateUserLogInToken/${id}`;
export const UPDATE_RISK_PRESENCE_MAP_URL = `${API_ROOT}/updateRiskPresenceMap`;
export const SEND_EMAIL_URL = `${API_ROOT}/sendEmail`;
export const UPLOAD_COASTAL_FILE_DATA_URL = `${API_ROOT}/uploadCoastalFileData`;
export const COASTAL_FILE_DATA_URL = (date) => `${API_ROOT}/getCoastalFileData/${date}`
export const OFFSHORE_NEARSHORE_DATA_URL = (date) =>`${API_ROOT}/getOffshoreNearshoreFileData/${date}`





