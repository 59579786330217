import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import jamaicaData from "./data/jamaica.json";
import Map from "./Map";
import {
  GET_RISK_PRESENCE_DATE_URL,
  GET_RISK_PRESENCE_MAP_DATA_URL,
  GET_RISK_PRESENCE_MAP_OFFSHORE_NEARSHORE_URL,
} from "../../apiEndPoints";

const options = {
  center: [18.102, -77.8339],
  zoom: 6.2,
  maxBounds: latLngBounds([18.5243, -78.3939], [17.6992, -76.1731]),
};

const JamaicaMap = () => {
  const [selectedParish, setSelectedParish] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [mergedData, setMergedData] = useState(null);
  const [mapDate, setMapDate] = useState(null);
  const [map_date_temp, setMapDateTemp] = useState("B2022_10");

  function setCommunity(communityName) {
    localStorage.setItem("communityName", communityName);
  }
  function setParishName(parishName) {
    localStorage.setItem("parishName", parishName);
  }

  useEffect(() => {
    getMapData();
    console.log(mapData);
  }, []);

  function getMapData() {
    Promise.all([
      fetch(GET_RISK_PRESENCE_MAP_DATA_URL).then((response) => response.json()),
      fetch(GET_RISK_PRESENCE_MAP_OFFSHORE_NEARSHORE_URL).then((response) =>
        response.json()
      ),
      fetch(GET_RISK_PRESENCE_DATE_URL).then((response) => response.json()),
    ])
      .then(([mapDataResponse, mergedDataResponse, mapDateResponse]) => {
        setMapData(mapDataResponse);
        setMergedData(mergedDataResponse);
        setMapDate(
          mapDateResponse[0].map_year +
            "_" +
            mapDateResponse[0].map_month +
            "_" +
            mapDateResponse[0].map_day
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  useEffect(() => {
    const svgPaths = document.querySelectorAll('path[id^="O"], path[id^="N"]');
    svgPaths.forEach((path) => {
      const id = path.getAttribute("id");
      const feature = mergedData.features.find((f) => f.properties.id === id);

      if (feature) {
        const value = feature.properties[mapDate];
        if (value === 0) {
          path.setAttribute("fill", "#FFFFFE");
        } else if (value === 1) {
          path.setAttribute("fill", "#F5C1C0");
        } else if (value === 2) {
          path.setAttribute("fill", "#DA9FFF");
        }

        // Mouseover event listener to show color name
        path.addEventListener("mouseover", (e) => {
          let presence;
          if (value === 0) {
            presence = "Absence of Sargassum";
          } else if (value === 1) {
            presence = "Minor presence of Sargassum";
          } else if (value === 2) {
            presence = "Major presence of Sargassum";
          } else {
            presence = "Absence of Sargassum";
          }

          // Create a new div element for the popup
          const popup = document.createElement("div");
          popup.textContent = presence;
          popup.style.position = "absolute";
          popup.style.textAlign = "center";
          popup.style.left = `${e.clientX}px`; // Adjust this value
          popup.style.top = `${e.clientY + 500}px`; // Adjust this value
          popup.style.backgroundColor = "#f5f5f5";
          popup.style.border = "1px solid #ccc";
          popup.style.padding = "10px";
          popup.style.borderRadius = "10px";
          popup.style.boxShadow = "0 1px 3px rgba(0,0,0,0.1)";
          popup.style.maxWidth = "200px";
          popup.style.fontFamily = "Arial, sans-serif";
          popup.style.fontSize = "14px";
          popup.style.color = "#333";
          document.body.appendChild(popup);

          // Add a pseudo-element for the chat bubble arrow
          const arrow = document.createElement("div");
          arrow.style.position = "absolute";
          arrow.style.bottom = "-10px";
          arrow.style.left = "20px";
          arrow.style.width = "0";
          arrow.style.height = "0";
          arrow.style.borderLeft = "10px solid transparent";
          arrow.style.borderRight = "10px solid transparent";
          arrow.style.borderTop = "10px solid #f5f5f5";

          // Remove the popup when the mouse leaves the path
          path.addEventListener("mouseout", () => {
            document.body.removeChild(popup);
          });
        });
      }
    });
  }, [mergedData, mapDate]);
  const handleClick = (e) => {
    setSelectedParish(e.target);
  };

  const handleMouseOut = (e) => {
    e.target.setStyle(e.target.options.style);
    setSelectedParish(null);
  };

  const riskData = {
    0: "rgba(204, 204, 204, 0.12)",
    1: "rgba(204, 204, 204, 0.12)",
    2: "#9CCB48",
    3: "#FFFF54",
    4: "#E78634",
    5: "#E93323",
  };

  return (
    <div className="mapbox" id="mapbox">
      {mapData ? (
        mergedData ? (
          <div>
            <section className="my-map map-content">
              <MapContainer
                className="map-container"
                {...options}
                zoomControl={false}
                touchZoom={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
              >
                <TileLayer
                  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                  // attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  minZoom={8}
                  opacity={0}
                />

                <GeoJSON
                  data={mapData}
                  style={(feature) => {
                    let risk = riskData[feature.properties.map_date_temp];
                    return {
                      color: "white",
                      weight: 13,
                      className: "drop-shadow-paths",
                      boxShadow: `0px 0px 10px 2px ${risk}`, // add box-shadow
                    };
                  }}
                  highlightStyle={(feature) => {
                    let risk = riskData[feature.properties.B2022_01];
                    return {
                      color: "white",
                      fillColor: risk,
                      weight: 10,
                      className: "drop-shadow-paths",
                      boxShadow: `0px 0px 10px 2px ${risk}`, // add box-shadow
                    };
                  }}
                  onEachFeature={(feature, layer) => {
                    layer.bindPopup(
                      `<div class="popup-container">
                              <h3 class="popup-header">${
                                feature.properties.NAME
                              }</h3>
                              <p class="popup-text popup-text-parish text-align-center">${
                                feature.properties.PARISH
                              }</p>
                              <div class="eightpx-spacer"></div>
                              <p class="popup-text text-align-center popup-text-beaching-risk">Beaching Risk</p>
                              <p class="text-align-center popup-text-beaching-risk-count">${
                                feature.properties.map_date_temp
                              }/5</p>
                              <div class="eightpx-spacer"></div>
                              <a href="/community-bulletin"><button class="pop-up-button" onClick={() => setCommunity(feature.properties.NAME)}>Read More</button></a>
                              <a href="/community-bulletin"><button class="pop-up-button" onClick={() => setCommunity(feature.properties.NAME)}>Read More</button></a>

                              <a href="/community-bulletin"}><button class="pop-up-button">Read More</button></a>  
                              <div class="hidden" >${console.log(
                                feature.properties
                              )}</div>
                              <div class="hidden" >${setParishName(
                                feature.properties.PARISH
                              )}</div>
                            </div>`,
                      {
                        closeButton: false,
                      }
                    );
                    layer.on({
                      // mouseover: (e) => {
                      //   layer.setStyle({
                      //     weight: 15,
                      //     fillOpacity: 0.7,
                      //   });
                      // },
                      // mouseout: (e) => {
                      //   layer.setStyle({
                      //     weight: 10,
                      //     fillOpacity: 1,
                      //   });
                      // },
                      // click: (e) => {
                      //   setSelectedParish(e.target);
                      //   setCommunity(feature.properties.TAG);
                      // },
                    });
                  }}
                />

                <GeoJSON
                  data={mapData}
                  style={(feature) => {
                    var mapDate_split = mapDate.split("_");
                    var coastline_date =
                      mapDate_split[0] + "_" + mapDate_split[1];
                    const userInput = "B" + coastline_date; // replace with user input
                    //const beachingRisk = feature.properties[userInput];
                    //console.log(beachingRisk);

                    let risk = riskData[feature.properties[userInput]];

                    //kayvia here for z index
                    // console.log(`risk-level-${feature.properties[userInput]}`)
                    return {
                      color: risk,
                      weight: 10,
                      // boxShadow: `0px 0px 10px ${risk}`,
                      className: `drop-shadow-paths risk-level-${feature.properties[userInput]}`,

                      // className: `drop-shadow-paths risk-level-${feature.properties.map_date_temp}`,
                    };
                  }}
                  highlightStyle={(feature) => {
                    let risk = riskData[feature.properties.B2022_10];
                    return {
                      //color: risk,
                      //fillColor: risk,
                      // color: 'rgba(255, 136, 136, 0.34)',
                      // weight: 10,
                      // boxShadow: `0px 0px 10px ${risk}`,
                      // className: 'drop-shadow-paths'
                    };
                  }}
                  onEachFeature={(feature, layer) => {
                    layer.bindPopup(
                      `<div class="popup-container">
                          <h3 class="popup-header">${feature.properties.NAME}</h3>
                          <p class="popup-text">Parish: ${feature.properties.PARISH}</p>
                          <p class="popup-text">Tag: ${feature.properties.TAG}</p>
                          <p class="popup-text">Tag: ${feature.properties.map_date_temp}</p>
                        </div>`,
                      {
                        className: "my-popup",
                        closeButton: false,
                      }
                    );
                    layer.on({
                      mouseover: (e) => {
                        var mapDate_split = mapDate.split("_");

                        var coastline_date =
                          mapDate_split[0] + "_" + mapDate_split[1];
                        const userInput = "B" + coastline_date; // replace with user input
                        const beachingRisk = feature.properties[userInput];
                        console.log(beachingRisk);

                        e.target.setStyle(e.target.options.highlightStyle);
                        e.target
                          .bindPopup(
                            `<div class="popup-container">
                                <h3 class="popup-header">${
                                  feature.properties.NAME
                                }</h3>
                                <p class="popup-text popup-text-parish text-align-center">${
                                  feature.properties.PARISH
                                }</p>
                                <div class="eightpx-spacer"></div>
                                <p class="popup-text text-align-center popup-text-beaching-risk">Beaching Risk</p>
                                <p class="text-align-center popup-text-beaching-risk-count">${beachingRisk}/5</p>
                                <div class="eightpx-spacer"></div>
                                <a href="/community-bulletin"}><button class="pop-up-button">Read More</button></a>  
                                <div class="hidden" >${setCommunity(
                                  feature.properties.NAME
                                )}</div>
                                <div class="hidden" >${setParishName(
                                  feature.properties.PARISH
                                )}</div>
                              </div>`,
                            {
                              className: "my-popup",
                              closeButton: false,
                            }
                          )
                          .openPopup();
                        setTimeout(() => {
                          layer.closePopup();
                        }, 5000); /**Changes time of popup */
                      },
                      mouseout: handleMouseOut,
                      click: handleClick,
                    });
                  }}
                />

                <GeoJSON
                  className="Jamaica-parishes"
                  data={jamaicaData}
                  style={{ color: "#BABABA", fillColor: "white", weight: 0.4 }}
                  highlightStyle={{
                    color: "black ",
                    fillColor: "black",
                    weight: 0.01,
                  }}
                />
              </MapContainer>
              <Map />
            </section>
          </div>
        ) : (
          <div>Loading...</div>
        )
      ) : (
        <div>Loading...</div>
      )}
      <div></div>
    </div>
  );
};

export default JamaicaMap;
